import {useTheme} from '@mui/material'

import {useMaterialWithRecipesQuery} from '../hooks'

import {MaterialDetailsCard} from './MaterialDetailsCard'

import {Skeleton} from '@settings/modules/common/components/skeletons'
import {useUrlParam} from '@settings/modules/routing'

export const PageMaterialDetails: React.FC = () => {
  const materialId = Number(useUrlParam('materialId'))
  const {data: material} = useMaterialWithRecipesQuery(materialId)
  const {spacing} = useTheme()
  return material ? (
    <MaterialDetailsCard key={material.id} material={material} />
  ) : (
    <Skeleton height={String(spacing(55))} />
  )
}
