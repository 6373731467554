import {dataTestId} from '@hconnect/uikit'
import {Autocomplete, TextField, Typography, Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {submitOnBlurAndEnterProps} from '../../../../common/utils'
import {Product} from '../../../types'

export interface ProductCodeAutocompleteProps {
  productCode: string
  onChange: (code: string) => void
  errorMessage?: string
  isDisabled: boolean
  submit: () => void
  productOptions: Product[]
  productsByCode: Record<string, Product>
}

export const ProductCodeAutocomplete: React.FC<ProductCodeAutocompleteProps> = ({
  productCode,
  onChange,
  errorMessage,
  isDisabled,
  submit,
  productOptions,
  productsByCode
}) => {
  const {t} = useTranslation()

  const productCodes = productOptions.map(({code}) => code)
  return (
    <Autocomplete
      {...submitOnBlurAndEnterProps(submit)}
      sx={{width: (theme) => theme.spacing(85)}}
      disabled={isDisabled}
      options={productCodes}
      value={productCode}
      onChange={(e, selectedCode) => onChange(selectedCode ?? '')}
      isOptionEqualToValue={(option, inputValue) => option === inputValue || inputValue === ''}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            sx={{pr: 0}}
            variant="outlined"
            label={t('materialsSettings.product')}
            helperText={errorMessage}
            error={Boolean(errorMessage)}
            placeholder={t('materialsSettings.productAutocompletePlaceholder')}
            {...dataTestId('product_code_input')}
          />
        )
      }}
      filterOptions={(options, {inputValue}) =>
        options.filter(
          (code) => code.includes(inputValue) || productsByCode[code].name.includes(inputValue)
        )
      }
      getOptionLabel={(code) => {
        return code ? `${code} ${productsByCode[code].name}` : ''
      }}
      renderOption={(props, code) => (
        <Stack
          {...props}
          sx={{
            '&.MuiAutocomplete-option': {
              alignItems: 'flex-start'
            }
          }}
          component="li"
          spacing={0.5}
        >
          <Typography variant="subtitle1">{code}</Typography>
          <Typography variant="caption">{productsByCode[code].name}</Typography>
        </Stack>
      )}
    />
  )
}
