import {MaterialType, AssetResponse} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {InfoOutlined} from '@mui/icons-material'
import {Box, Stack} from '@mui/material'
import React from 'react'
import {useFormContext, UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useIsMutating} from 'react-query'

import {ControlledAutocomplete, ControlledTextField} from '../../../../common/components'
import {requiredValidator} from '../../../../common/utils'
import {AutocompleteOption, MaterialWithRecipes} from '../../../types'
import {MaterialFormDefaultValues} from '../../MaterialDetailsCard/hooks/useMaterialFormDefaultValues'

import {MaterialSourceInputs} from './MaterialSourceInputs'

import {Schedule} from '@settings/modules/common/types'

interface MaterialFormProps {
  material: MaterialWithRecipes | undefined
  isReadOnly: boolean
  schedule: Schedule
  assets: AssetResponse[]
  globalMaterialOptions: AutocompleteOption[]
}

export const MaterialForm: React.FC<MaterialFormProps> = ({
  material,
  isReadOnly,
  schedule,
  assets,
  globalMaterialOptions
}) => {
  const {t} = useTranslation()

  const {control}: UseFormReturn<MaterialFormDefaultValues> = useFormContext()

  const runningMutations = useIsMutating(['editMaterial'])
  const isMaterialSaving = Boolean(runningMutations)

  const isNewMaterial = material === undefined

  return (
    <Stack direction={'column'} spacing={2} {...dataTestId('add_edit_material_form')}>
      <Box sx={{width: {xs: '100%', sm: 392}}}>
        <ControlledAutocomplete
          control={control}
          formDataName="type"
          options={Object.values(MaterialType)}
          translateOption={(option) => t(`materialsSettings.materialTypes.${option}`)}
          label={t('materialsSettings.type')}
          rules={requiredValidator(t)}
          disabled={isReadOnly || !isNewMaterial}
          sx={{
            '& .MuiInputBase-root': {
              height: '56px',
              borderRadius: '8px',
              background: (theme) => theme.palette.background.paper,
              boxShadow: 'none'
            }
          }}
          {...dataTestId('material_type_input')}
        />
      </Box>
      {isNewMaterial && (
        <Stack
          direction="row"
          sx={{
            width: {xs: '100%', sm: 392},
            fontSize: '12px',
            lineHeight: '16px',
            color: (theme) => theme.palette.text.secondary
          }}
          {...dataTestId('material_form_tooltip')}
        >
          <InfoOutlined sx={{mr: 1, height: 16, width: 16}} />
          {t('materialsSettings.typeTooltip')}
        </Stack>
      )}
      <ControlledTextField
        control={control}
        formDataName="name"
        rules={requiredValidator(t)}
        label={t('materialsSettings.localName')}
        TextFieldProps={{
          disabled: isReadOnly || isMaterialSaving,
          sx: {
            width: {xs: '100%', sm: 392},
            '& .MuiInputBase-root': {
              background: (theme) => theme.palette.background.paper
            }
          }
        }}
        {...dataTestId('material_name_input')}
      />
      <ControlledTextField
        control={control}
        formDataName="description"
        label={t('materialsSettings.descritpion')}
        TextFieldProps={{
          disabled: isReadOnly || isMaterialSaving,
          sx: {
            width: {xs: '100%', sm: 392},
            '& .MuiInputBase-root': {
              background: (theme) => theme.palette.background.paper
            }
          }
        }}
        {...dataTestId('material_description_input')}
      />
      {!isNewMaterial && (
        <Box sx={{width: {xs: '100%', sm: 392}}}>
          <ControlledAutocomplete
            control={control}
            formDataName="globalMaterial"
            options={globalMaterialOptions}
            label={t('materialsSettings.globalMaterial')}
            disableClearable={false}
            disabled={isReadOnly || isMaterialSaving}
            sx={{
              '& .MuiInputBase-root': {
                height: '56px',
                borderRadius: '8px',
                background: (theme) => theme.palette.background.paper,
                boxShadow: 'none'
              }
            }}
            {...dataTestId('material_global_material_input')}
          />
        </Box>
      )}
      <MaterialSourceInputs
        material={material}
        schedule={schedule}
        assets={assets}
        disabled={isReadOnly || isMaterialSaving}
      />
    </Stack>
  )
}
