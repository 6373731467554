import {dataTestId} from '@hconnect/uikit'
import {CardTitle} from '@hconnect/uikit/src/lib2'
import {Check, Clear} from '@mui/icons-material'
import {Button, Stack} from '@mui/material'
import {useFormContext, UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {HeaderTab} from '../../../common/components/HeaderTabs'
import {MaterialSettingsTabs} from '../../../common/enums'
import {MaterialWithRecipes} from '../../types'

import {useMaterialFormCancel, useMaterialFormSubmit} from './hooks'
import {MaterialFormDefaultValues} from './hooks/useMaterialFormDefaultValues'

interface MaterialDetailsCardHeaderProps {
  material: MaterialWithRecipes | undefined
  isMaterialSaving: boolean
  selectedTab: HeaderTab
}

export const MaterialDetailsCardHeader: React.FC<MaterialDetailsCardHeaderProps> = ({
  material,
  isMaterialSaving,
  selectedTab
}) => {
  const {t} = useTranslation()
  const materialForm: UseFormReturn<MaterialFormDefaultValues> = useFormContext()

  const isNewMaterial = material === undefined

  const materialFormCancel = useMaterialFormCancel({material, materialForm})
  const materialFormSubmit = useMaterialFormSubmit({material, materialForm})

  const shouldSaveCancelButtonsBeDisplayed = [
    MaterialSettingsTabs.General,
    MaterialSettingsTabs.Classification
  ].includes(selectedTab.value as MaterialSettingsTabs)

  return (
    <Stack direction="row" justifyContent="space-between">
      <CardTitle variant="h3" sx={{mb: 0}}>
        {isNewMaterial ? t('materialsSettings.newMaterial') : material.name}
      </CardTitle>
      {shouldSaveCancelButtonsBeDisplayed && (
        <Stack direction="row" spacing={2}>
          <Button
            color="secondary"
            startIcon={<Clear />}
            disabled={isMaterialSaving || (!isNewMaterial && !materialForm.formState.isDirty)}
            onClick={materialFormCancel}
            {...dataTestId('material_form_cancel_button')}
          >
            {t('common.cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Check />}
            disabled={isMaterialSaving || !materialForm.formState.isDirty}
            onClick={() => void materialFormSubmit()}
            {...dataTestId('material_form_save_button')}
          >
            {t('common.save')}
          </Button>
        </Stack>
      )}
    </Stack>
  )
}
