import {dataTestId} from '@hconnect/uikit'
import {CardBox, Loader} from '@hconnect/uikit/src/lib2'
import {SendOutlined as SendOutlinedIcon, ChevronLeft as ChevronLeftIcon} from '@mui/icons-material'
import {Typography, Stack, TextField, InputAdornment, ButtonBase, Link, Box} from '@mui/material'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useUrlParam} from '../../../../modules/routing'
import {useAddComment} from '../../hooks/useAddComment'
import {useKpiEvents} from '../../hooks/useKpiEvents'
import {Comment} from '../../types'

import {EmptyCommentsList} from './EmptyCommentsList'
import {KpiComment} from './KpiComment'

export const KpiCommentsView = ({
  kpiId,
  kpiName,
  unitName,
  closeComments,
  backButtonText,
  onSuccess
}: {
  kpiId: string
  closeComments: () => void
  backButtonText?: string
  kpiName: string
  unitName: string
  onSuccess: () => void
}) => {
  const {t} = useTranslation()

  const handleClick = () => closeComments()

  const {mutate: addComment} = useAddComment()

  const {data: comments, isLoading, isFetching} = useKpiEvents(kpiId)

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: {isDirty}
  } = useForm<Comment>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {value: ''}
  })

  const plantCode = useUrlParam('plantCode')

  const submit = handleSubmit(({value}) => {
    if (!value || !isDirty) return
    addComment(
      {plantCode, kpiValueId: kpiId, value},
      {
        onError: () => reset(),
        onSuccess: () => {
          setValue('value', ''), onSuccess()
        }
      }
    )
  })

  const isLoadingOrIsFetching = isLoading || isFetching
  const showComments = !isLoadingOrIsFetching && comments && comments.events.length > 1

  return (
    <CardBox height="650px" {...dataTestId(`comments-container-${kpiName}`)}>
      <Stack height="100%" gap={2}>
        <Stack gap={3} sx={{flex: '0 0 20%'}}>
          {backButtonText && (
            <Link
              component="span"
              sx={{
                textDecoration: 'none'
              }}
              {...dataTestId(`go-back-to-${backButtonText}`)}
              onClick={handleClick}
            >
              <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <ChevronLeftIcon sx={{verticalAlign: 'text-bottom'}} />
                <Typography>{backButtonText}</Typography>
              </Box>
            </Link>
          )}
          <Typography variant="h3">{`${kpiName} (${unitName})`}</Typography>
          <Controller
            control={control}
            name="value"
            rules={{
              validate: (value) => value.length > 0
            }}
            render={({field: {ref, value, onChange}}) => (
              <TextField
                label={t('janusConfig.dailyEntry.newComment')}
                {...dataTestId('new-comment-text-field')}
                value={value}
                inputRef={ref}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    void submit()
                  }
                }}
                onChange={onChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" onClick={() => void submit()}>
                      <ButtonBase {...dataTestId('add-new-comment-button')}>
                        <SendOutlinedIcon />
                      </ButtonBase>
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        </Stack>
        <Stack
          sx={{
            flex: '1',
            overflowY: 'auto'
          }}
        >
          {showComments ? (
            <Stack gap={3}>
              {comments.events.map((comment) => (
                <KpiComment event={comment} key={`comment-${comment.id}-${comment.version}`} />
              ))}
            </Stack>
          ) : isLoadingOrIsFetching ? (
            <Loader />
          ) : (
            <EmptyCommentsList />
          )}
        </Stack>
      </Stack>
    </CardBox>
  )
}
