import {Status, AssetResponse} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {NumberLetterSequenceIndicator} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined} from '@mui/icons-material'
import {Box, Typography, TextField, ToggleButtonGroup, ToggleButton, Divider} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useAssetsQuery} from '../../../../assets/hooks'
import {findAllOperationModes} from '../../../../assets/utils'
import {DeleteButton} from '../../../../common/components'
import {RecipeComponentType} from '../../../../common/enums'
import {useScheduleQuery} from '../../../../common/hooks'
import {useConfirmDialog} from '../../../../common/providers'
import type {Schedule} from '../../../../common/types'
import {submitOnBlurAndEnterProps, requiredValidator} from '../../../../common/utils'
import {useUrlParam} from '../../../../routing'
import {
  useAddMaterialRecipe,
  useEditMaterialRecipe,
  useDeleteMaterialRecipe,
  useMaterialsQuery
} from '../../../hooks'
import {Recipe, RecipeComponent, NewRecipe} from '../../../types'

import {getAvailableMaterials} from './helpers/getAvailableMaterials'
import {RecipeComponentAddDropdown} from './RecipeComponentAddDropdown'
import {RecipeComponentList} from './RecipeComponentList'
import {RecipeDeleteInfo} from './RecipeDeleteInfo'

interface RecipeFormProps {
  index: number
  recipe: Recipe | NewRecipe
  isReadOnly: boolean
  setNewRecipe?: (recipe: NewRecipe | undefined) => void
}

export const RecipeForm: React.FC<RecipeFormProps> = ({
  index,
  recipe,
  setNewRecipe,
  isReadOnly
}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {data: materials} = useMaterialsQuery()
  if (!materials) throw new Error('Materials data are missing!')
  const {mutateAsync: addRecipe} = useAddMaterialRecipe()
  const {mutateAsync: editRecipe} = useEditMaterialRecipe()
  const {mutate: deleteRecipe} = useDeleteMaterialRecipe()
  const {openDialog} = useConfirmDialog()
  const {data: assets} = useAssetsQuery()
  const {data: schedule} = useScheduleQuery()

  const [newRecipeComponent, setNewRecipeComponent] = useState<
    Omit<RecipeComponent, 'id'> & {id: number | undefined}
  >()

  const {
    handleSubmit,
    control,
    reset,
    formState: {isDirty}
  } = useForm<Recipe | NewRecipe>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: recipe
  })

  const submit = handleSubmit(async (recipe) => {
    if (!isDirty) return
    if (recipe.status === Status.New) {
      await addRecipe(
        {
          plantCode,
          materialId: recipe.mainMaterialId,
          recipeName: recipe.name
        },
        {onError: () => reset(), onSuccess: () => setNewRecipe?.(undefined)}
      )
    } else {
      await editRecipe(
        {
          plantCode,
          materialId: recipe.mainMaterialId,
          recipeId: recipe.id,
          recipeName: recipe.name
        },
        {onError: () => reset(), onSuccess: () => reset(recipe)}
      )
    }
  })

  const isNewRecipe = recipe.status === Status.New

  const inputComponents = recipe.components.filter(
    (component) => component.type === RecipeComponentType.Input
  )
  const outputComponents = recipe.components.filter(
    (component) => component.type === RecipeComponentType.Output
  )

  const [detailType, setDetailType] = useState<RecipeComponentType>(RecipeComponentType.Input)

  const handleDeleteRecipe = (schedule: Schedule, assets: AssetResponse[]) => {
    if (isNewRecipe) {
      return setNewRecipe?.(undefined)
    }
    const operationModes = findAllOperationModes(assets, [recipe])
    const scheduleItems = Object.values(schedule.schedules).filter(
      (item) => operationModes.find((mode) => mode.id === item.assetOperationModeId) !== undefined
    )
    openDialog({
      title: t('materialsSettings.deleteRecipe'),
      mainAction: {
        text: t('common.delete'),
        color: 'error',
        icon: <DeleteOutlined />,
        onAction: () => {
          deleteRecipe({
            plantCode,
            materialId: recipe.mainMaterialId,
            recipeId: recipe.id
          })
        }
      },
      additionalContent: (
        <RecipeDeleteInfo
          scheduleItems={scheduleItems}
          recipe={recipe}
          operationModes={operationModes}
        />
      )
    })
  }

  const total = React.useMemo(() => {
    const initials = (newRecipeComponent ? newRecipeComponent.fraction : 0) * 100
    const total = recipe.components
      .filter((component) => component.type === detailType)
      .reduce((sum, component) => sum + component.fraction * 100, 0)
    return Math.round((total + initials) * 100) / 100
  }, [recipe.components, newRecipeComponent, detailType])

  const selectedRecipeComponents =
    detailType === RecipeComponentType.Input ? inputComponents : outputComponents

  const availableMaterials =
    recipe.status !== Status.New ? getAvailableMaterials(materials, recipe) : []

  const onAddRecipeComponent = (type: RecipeComponentType) => {
    if (availableMaterials.length) {
      // if there not saved component of a different type, then create new component
      if (!newRecipeComponent || newRecipeComponent.type !== type) {
        setNewRecipeComponent({
          id: undefined,
          type: type,
          fraction: 0.1,
          material: {
            id: availableMaterials[0].id,
            name: availableMaterials[0].name,
            pxTrendCounters: availableMaterials[0].pxTrendCounters
          }
        })
      }
      setDetailType(type)
    }
  }

  useEffect(() => {
    if (newRecipeComponent?.type !== detailType) {
      setNewRecipeComponent(undefined)
    }
  }, [newRecipeComponent, detailType])

  return (
    <Box sx={{mb: 3}} {...dataTestId('recipe_form')}>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <NumberLetterSequenceIndicator numberIndex={index} />
        <Controller
          control={control}
          name="name"
          rules={requiredValidator(t)}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <TextField
              sx={{
                ml: 2,
                width: (theme) => theme.spacing(52)
              }}
              variant="outlined"
              inputRef={ref}
              label={t('materialsSettings.recipeName')}
              value={value}
              onChange={onChange}
              helperText={error?.message}
              error={Boolean(error?.message)}
              disabled={isReadOnly}
              {...submitOnBlurAndEnterProps(submit)}
              {...dataTestId('recipe_name_input')}
            />
          )}
        />
        {recipe.status !== Status.New && (
          <RecipeComponentAddDropdown
            disabled={isReadOnly}
            componentType={newRecipeComponent?.type}
            onAddOutput={() => onAddRecipeComponent(RecipeComponentType.Output)}
            onAddIngredient={() => onAddRecipeComponent(RecipeComponentType.Input)}
          />
        )}
        {schedule && assets !== undefined && (
          <DeleteButton
            sx={{ml: 2}}
            onClick={() => handleDeleteRecipe(schedule, assets)}
            disabled={isReadOnly}
            {...dataTestId('delete_recipe_button')}
          />
        )}
      </Box>
      {inputComponents.length === 0 && !newRecipeComponent && (
        <Typography variant="body2" sx={{mt: 2, ml: 4.5, fontStyle: 'italic'}}>
          {t('materialsSettings.noIngredients')}
        </Typography>
      )}
      <ToggleButtonGroup
        value={detailType}
        disabled={isNewRecipe}
        onChange={(_e, value: RecipeComponentType) => setDetailType(value)}
        fullWidth
        sx={{
          ml: 4,
          mt: 2,
          width: (theme) => theme.spacing(52)
        }}
        color="primary"
        exclusive
      >
        <ToggleButton value={RecipeComponentType.Input} {...dataTestId('material_ingredients')}>
          {t('materialsSettings.ingredients', {amount: inputComponents.length})}
        </ToggleButton>
        <ToggleButton value={RecipeComponentType.Output} {...dataTestId('material_outputs')}>
          {t('materialsSettings.outputs', {amount: outputComponents.length})}
        </ToggleButton>
      </ToggleButtonGroup>
      {/* component could be added only to existing recipe */}
      {!isNewRecipe && (
        <>
          <RecipeComponentList
            materials={materials}
            recipe={recipe}
            type={detailType}
            recipeComponents={selectedRecipeComponents}
            newRecipeComponent={newRecipeComponent}
            setNewRecipeComponent={setNewRecipeComponent}
            isReadOnly={isReadOnly}
          />
          {selectedRecipeComponents.length > 0 && (
            <>
              <Divider sx={{ml: 4, mt: 2, width: (theme) => theme.spacing(52)}} />
              <Box
                className={'recipe-component-list'}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: (theme) => theme.spacing(52),
                  ml: 4,
                  pt: 2,
                  pb: 2
                }}
              >
                <Typography variant="body1">
                  {t(
                    detailType === RecipeComponentType.Input
                      ? 'materialsSettings.combinedIngredients'
                      : 'materialsSettings.combinedOutput'
                  )}
                </Typography>
                <Typography variant="h5" sx={{fontWeight: 'bold'}}>
                  {total}%
                </Typography>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  )
}
