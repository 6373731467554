import {MaterialSource} from '@hconnect/common/types'
import {UseFormReturn} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

import {getUrl, PLANT_ROUTES, useUrlParam} from '../../../../routing'
import {
  useAddMaterial,
  useEditMaterial,
  useEditMaterialAttribute,
  useDeleteGlobalMaterial
} from '../../../hooks'
import {ClassificationDTO, MaterialWithRecipes} from '../../../types'

import {MaterialFormDefaultValues} from './useMaterialFormDefaultValues'

import {MaterialApiKeys} from '@settings/api/mutations'

interface useMaterialFormSubmitProps {
  material: MaterialWithRecipes | undefined
  materialForm: UseFormReturn<MaterialFormDefaultValues>
}

export const useMaterialFormSubmit = ({
  material,
  materialForm: {
    handleSubmit,
    reset,
    formState: {isDirty, dirtyFields}
  }
}: useMaterialFormSubmitProps) => {
  const plantCode = useUrlParam('plantCode')
  const navigate = useNavigate()

  const {mutate: addMaterial} = useAddMaterial()
  const {mutate: editMaterial} = useEditMaterial()

  // TODO <HCP-82759>: remove after classification tab is implemented
  const {mutate: editMaterialAttribute} = useEditMaterialAttribute()
  const {mutate: deleteGlobalMaterial} = useDeleteGlobalMaterial()

  const isNewMaterial = material === undefined

  return handleSubmit((params) => {
    const {type, name, description, globalMaterial, source, boughtFrom, classification} = params

    // A safety net since type field cannot be changed after material creation
    // Also we don't want to send a request if nothing has changed
    if (type === null || source === null || !isDirty) return

    const materialBoughtFrom = source === MaterialSource.ProducedInPlant ? undefined : boughtFrom

    const commonMaterialDto = {
      name,
      description,
      source,
      boughtFrom: materialBoughtFrom
    }

    if (isNewMaterial) {
      return addMaterial(
        {
          plantCode,
          dto: {type, ...commonMaterialDto}
        },
        {
          onError: () => reset(),
          onSuccess: (createdMaterial) =>
            navigate(
              getUrl(PLANT_ROUTES.MATERIALS.TYPE.MATERIAL_TYPE.ID.MATERIAL_ID.path, {
                plantCode,
                materialType: createdMaterial.type,
                materialId: String(createdMaterial.id)
              })
            )
        }
      )
    }
    editMaterial(
      {
        plantCode,
        materialId: material.id,
        dto: {
          ...commonMaterialDto,
          classification: Object.fromEntries(
            Object.entries(classification).filter(([, value]) => value !== null)
          ) as ClassificationDTO
        }
      },
      {
        onError: () => reset(),
        onSuccess: () => {
          // TODO <HCP-82759>: remove after classification tab is implemented
          if (dirtyFields.globalMaterial) {
            if (globalMaterial !== null)
              editMaterialAttribute(
                {
                  key: MaterialApiKeys.globalMaterial,
                  plantCode,
                  materialId: material.id,
                  globalMaterialId: Number(globalMaterial.id)
                },
                {
                  onError: () => reset()
                }
              )
            else
              deleteGlobalMaterial(
                {plantCode, materialId: material.id},
                {
                  onError: () => reset()
                }
              )
          }
          reset(params)
        }
      }
    )
  })
}
