import {dataTestId} from '@hconnect/uikit'
import {Loader} from '@hconnect/uikit/src/lib2'
import {ChevronRight, ExpandMore, Add as AddIcon} from '@mui/icons-material'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField
} from '@mui/material'
import {styled} from '@mui/material/styles'
import {debounce} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {NodeApi, NodeRendererProps, Tree, TreeApi} from 'react-arborist'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'
import useResizeObserver from 'use-resize-observer'

import {useGetPlantStructure} from '../../../common/hooks/queries/usePlantStructureQuery'
import {useLoginDetails} from '../../../common/providers'
import {StructureNode} from '../../../common/types'
import {PlantStructureFilterOptions} from '../../enums'
import {useSelectedItem} from '../SelectedItemProvider'

import {AddSubdomain} from './AddSubdomain'
import {useItemLink} from './ItemLinkContext'

const StyledListItem = styled(ListItem)(() => ({
  '&:hover .icon-button': {
    visibility: 'visible'
  },
  '.icon-button': {
    visibility: 'hidden'
  }
}))

const allowedIds = [
  '6b8a54b2-cfa6-407c-8ad5-e88780152f10',
  '86f532f3-66d0-4545-88da-510ec1ad70e6',
  '6296fa10-24f2-4528-bfbd-468d08cb1d2d',
  '5264e312-4be4-4fa3-a9e6-e09723ffc709',
  '0ccfea22-d1fa-4b7f-bd13-9ffbb2f8690c',
  '4767a8df-a149-41b0-8784-73c01b586a8e',
  '5f22fce9-0a59-45e5-8f65-f546d3b84536'
]

const Node = <T extends StructureNode>({node, style}: NodeRendererProps<T>) => {
  const {loginDetails} = useLoginDetails()
  const {setItemToLink} = useItemLink()

  const userId = loginDetails?.userId || ''
  const canAddSubdomain = allowedIds.includes(userId)

  const shouldHideArrow =
    node.isLeaf || node.data.children?.length === 0 || node.data.entityType === 'Plant'

  const shouldShowAddSubdomain =
    canAddSubdomain &&
    (node.data.entityType === 'Department' || node.data.entityType === 'Equipment')

  const handlePlusClick = (event: React.MouseEvent) => {
    setItemToLink(node.id)
    event.stopPropagation()
  }

  return (
    <Stack>
      <StyledListItem dense={true} disablePadding {...dataTestId(`list-item-${node.id}`)}>
        <ListItemButton
          style={style}
          sx={{
            backgroundColor: node.isSelected ? '#E6F0FB' : 'inherit',
            height: 56
          }}
        >
          <ListItemIcon
            sx={{
              display: shouldHideArrow ? 'none' : 'flex',
              minWidth: 30
            }}
            onClick={(e) => {
              node.isInternal && node.toggle()
              e.stopPropagation()
            }}
          >
            {node.isOpen ? <ExpandMore color="primary" /> : <ChevronRight color="primary" />}
          </ListItemIcon>
          <ListItemText
            sx={{pl: shouldHideArrow ? 4 : 0}}
            primaryTypographyProps={{noWrap: true}}
            primary={node.data.entityLabel}
          />
          {shouldShowAddSubdomain && (
            <IconButton className="icon-button" onClick={handlePlusClick}>
              <AddIcon />
            </IconButton>
          )}
        </ListItemButton>
      </StyledListItem>
      <Divider variant="middle" sx={{mx: 4}} />
    </Stack>
  )
}

const getPlantStructureProductFilter = (selectedFilterOption: PlantStructureFilterOptions) => {
  switch (selectedFilterOption) {
    case PlantStructureFilterOptions.Janus:
      return PlantStructureFilterOptions.Janus
    case PlantStructureFilterOptions.Mills:
    case PlantStructureFilterOptions.Kilns:
    case PlantStructureFilterOptions.Storages:
    case PlantStructureFilterOptions.All:
    default:
      return undefined
  }
}

export const PlantStructureTree = ({
  selectedFilterOption
}: {
  selectedFilterOption: PlantStructureFilterOptions
}) => {
  const {t} = useTranslation()
  const [filterText, setFilterText] = useState<string>()
  const [includeEquipment, setIncludeEquipment] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()

  const {setSelectedItem} = useSelectedItem()
  const {ref: treeContainerRef, width, height} = useResizeObserver()
  const treeRef = useRef<TreeApi<StructureNode>>()
  const {
    data: plantStructure,
    isLoading,
    isFetching
  } = useGetPlantStructure({
    includeEquipment,
    product: getPlantStructureProductFilter(selectedFilterOption)
  })

  const arboristTree = useMemo(() => (plantStructure ? [plantStructure] : []), [plantStructure])
  const debouncedSetFilterText = debounce((text: string) => setFilterText(text), 300)
  const plantId = plantStructure?.upmId ?? ''

  const searchMatch = useCallback(
    (node: NodeApi<StructureNode>) => {
      const filters: {hasField: boolean; isMatching: boolean}[] = [
        {
          hasField: !!filterText,
          isMatching: filterText
            ? node.data.name.toLowerCase().includes(filterText.toLowerCase())
            : false
        }
      ]
      return filters.every((filter) => !filter.hasField || filter.isMatching) || node.level === 0
    },
    [filterText]
  )

  const handleNodeSelected = useCallback(
    (node: NodeApi<StructureNode>) => {
      if (!node) return
      setSelectedItem(node.data)
      setSearchParams((params) => {
        params.set('entityId', node.data.upmId)
        return params
      })
    },
    [setSearchParams, setSelectedItem]
  )

  // sync the initial selection from the URL without traversing the tree manually
  useEffect(() => {
    if (isLoading) return
    const entityId = searchParams.get('entityId')
    if (entityId) {
      void treeRef.current
        ?.scrollTo(entityId)
        ?.then(() => treeRef.current?.select(entityId, {focus: false}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Box>
        {isFetching ? <LinearProgress /> : <div style={{height: 4}} />}
        <Stack
          direction="column"
          justifyContent="space-between"
          {...dataTestId('janus-plant-structure-menu')}
          alignItems={'left'}
          gap={2}
        >
          <Box px={2}>
            <TextField
              fullWidth
              label={t('janusConfig.plantSetup.freeTextFilter')}
              variant="filled"
              onChange={(e) => debouncedSetFilterText(e.target.value)}
              {...dataTestId('plant-structure-text-filter')}
            />
          </Box>
          <Box display="flex" gap={1} px={2} flexWrap={'wrap'} justifyContent="space-between">
            <Button
              onClick={() => setIncludeEquipment((prev) => !prev)}
              size="small"
              variant="text"
              startIcon={includeEquipment ? <VisibilityOffIcon /> : <VisibilityIcon />}
              sx={{p: 0.5}}
            >
              {includeEquipment
                ? t('janusConfig.action.hideEquipment')
                : t('janusConfig.action.showEquipment')}
            </Button>
            <Box display="flex" gap={1} flexWrap={'wrap'}>
              <Button
                onClick={() => treeRef.current?.openAll()}
                size="small"
                variant="text"
                startIcon={<UnfoldMoreIcon />}
                sx={{p: 0.5}}
              >
                {t('janusConfig.action.expand')}
              </Button>
              <Button
                onClick={() => {
                  treeRef.current?.closeAll()
                  treeRef.current?.open(plantId)
                }}
                size="small"
                variant="text"
                startIcon={<UnfoldLessIcon />}
                sx={{p: 0.5}}
              >
                {t('janusConfig.action.collapse')}
              </Button>
            </Box>
          </Box>
          <Box ref={treeContainerRef} height={'55vh'}>
            {arboristTree && (
              <Tree
                ref={treeRef}
                data={arboristTree}
                openByDefault={false}
                width={width}
                rowHeight={56}
                height={height}
                indent={20}
                searchTerm={filterText}
                searchMatch={searchMatch}
                idAccessor={(node) => node.upmId}
                onSelect={(nodes) => handleNodeSelected(nodes[0])}
                initialOpenState={{[plantId]: true}}
              >
                {Node}
              </Tree>
            )}
          </Box>
        </Stack>
      </Box>
      <AddSubdomain />
    </>
  )
}
