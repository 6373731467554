import {
  MaterialType,
  Status,
  MaterialSource,
  ProductType,
  MaterialBoughtFrom
} from '@hconnect/common/types'

import {RecipeComponentType, ProductForecastStatus} from '../../common/enums'

import {ClassificationDTO} from './materialClassification'

type MaterialBEStatus = Status.Created | Status.Edited
export interface Product {
  id: number
  code: string
  name: string
  type: ProductType
}

export type ProductForecastInfo = {
  status: ProductForecastStatus
}

export type ProductsForecastInfo = Record<string, ProductForecastInfo>

export type MaterialsProducts = Record<ProductType, Product[]>

interface ComponentMaterial {
  id: number
  name: string
  pxTrendCounters?: string[]
}

export interface RecipeComponent {
  id: number
  type: RecipeComponentType
  fraction: number
  material: ComponentMaterial
}

export interface RecipeComponentDTO {
  materialId: number
  fraction: number
  type: RecipeComponentType
}

export interface Recipe {
  id: number
  name: string
  mainMaterialId: number
  status: MaterialBEStatus
  components: RecipeComponent[]
}

export interface RecipeDTO {
  name: string
}

export interface NewRecipe extends RecipeDTO {
  status: Status.New
  mainMaterialId: number
  components: RecipeComponent[]
}

interface MaterialOrigin {
  source: MaterialSource
  boughtFrom?: MaterialBoughtFrom
}

export interface Material extends ComponentMaterial {
  description?: string
  origin: MaterialOrigin
  createdBy: string
  createdOn: string
  status: MaterialBEStatus
  type: MaterialType
  // TODO <HCP-82759>: source will be removed after we fully switch to origin field
  source: MaterialSource
  products: Product[]
  // TODO <HCP-82759>: global material will be removed after material classification is implemented
  globalMaterialId?: number
  globlaMaterialName?: string
  limsMaterials: LimsMaterial[]
  classification?: ClassificationDTO
}

export interface AddMaterialDTO {
  type: MaterialType
  name: string
  description: string
  source: MaterialSource
  boughtFrom?: MaterialBoughtFrom
}

export type EditMaterialDTO = Omit<AddMaterialDTO, 'type'> & {
  classification: ClassificationDTO
}

export interface MaterialWithRecipes extends Material {
  recipes: Recipe[]
}

export interface GlobalMaterial {
  id: number
  name: string
  type: MaterialType
}

export interface AutocompleteOption {
  id: string
  label: string
}

export interface LimsMaterial {
  id: number
  code: string
  name?: string
}

export type NewLimsMaterial = Pick<LimsMaterial, 'code' | 'name'> & {id?: number}

// Generic settings tree types
export type SettingsTreeNode<T extends MaterialTreeComponents> = {
  id: string
  name: string
  originalEntity: T
  children?: SettingsTree<T>
}
export type SettingsTree<T extends MaterialTreeComponents> = ReadonlyArray<SettingsTreeNode<T>>

// Material settings tree types
type NewMaterial = undefined
export type SelectedMaterial = Material | NewMaterial
type MaterialTreeComponents = MaterialType | SelectedMaterial
export type MaterialTreeNode = SettingsTreeNode<MaterialTreeComponents>
export type MaterialTree = SettingsTree<MaterialTreeComponents>

export const isNewMaterialGuard = (
  originalEntity: MaterialTreeComponents
): originalEntity is NewMaterial => originalEntity === undefined

export const isExistingMaterialGuard = (
  originalEntity: MaterialTreeComponents
): originalEntity is Material =>
  !isNewMaterialGuard(originalEntity) && (originalEntity as Material).status !== undefined
