import {MaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit/src/common/utils/testingUtils'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {
  ClassificationInputName,
  ClassificationMetadataEntry,
  ClassificationMetadataValueSet
} from '../../../types'
import {MaterialFormDefaultValues} from '../../MaterialDetailsCard/hooks'

import {ControlledCheckbox} from '@settings/modules/common/components/form/ControlledCheckbox'
import {requiredValidator} from '@settings/modules/common/utils'

type ClassificationBooleanValue = 'Yes' | 'No'

interface ClassificationCheckboxProps<T extends MaterialType> {
  materialType: T
  inputName: ClassificationInputName<T>
  metadata: ClassificationMetadataEntry<T>
  isDisabled: boolean
  onChangeCb?: () => void
}

export const ClassificationCheckBox = <T extends MaterialType>({
  materialType,
  inputName,
  metadata,
  isDisabled,
  onChangeCb
}: ClassificationCheckboxProps<T>) => {
  const {t} = useTranslation()
  const {control, watch} = useFormContext<MaterialFormDefaultValues>()

  const validValueSet = metadata.inputs[inputName].valueSets.find(
    (valueSet: ClassificationMetadataValueSet) => {
      // Agreement with BE: for now if valuesShownWnen is undefined, it's the only valid value set
      if (valueSet.valuesShownWhen === undefined) {
        return true
      }
      // Agreement with BE: for now any field can be dependant only on one other field
      const conditionForValueSet = valueSet.valuesShownWhen[0]

      const conditionCurrentFormValue = watch(
        `classification.${conditionForValueSet.inputName}`
      ) as string | null
      return conditionCurrentFormValue === conditionForValueSet.value
    }
  )

  return (
    <ControlledCheckbox
      control={control}
      formDataName={`classification.${inputName}`}
      label={t(`materialsSettings.classificationInputs.${materialType}.${inputName}`)}
      rules={requiredValidator(t)}
      disabled={isDisabled || validValueSet?.values.length === 0}
      formatValue={(checked) => (checked ? 'Yes' : 'No')}
      parseValue={(value) => (value as ClassificationBooleanValue) === 'Yes'}
      onChangeCb={onChangeCb}
      {...dataTestId(`${materialType}_classification_${inputName}`)}
    />
  )
}
