import {MaterialType, Status, AssetType, MaterialSource} from '@hconnect/common/types'
import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'

import {isMaterialTypeBoughtFromVendor} from '../../../src/modules/materials/helpers/getProductsPerMaterialSource'
import {RecipeComponentType} from '../../modules/common/enums'
import {getMaterialWithAttachedProduct} from '../../modules/materials/'
import {getMaterialWithAssignedLimsMaterial} from '../../modules/materials/components/materials-tabs/lims-materials/helpers'
import type {
  Material,
  AddMaterialDTO,
  EditMaterialDTO,
  MaterialWithRecipes,
  RecipeDTO,
  Recipe,
  RecipeComponent,
  RecipeComponentDTO,
  Product,
  LimsMaterial
} from '../../modules/materials/types'
import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx, saveScenario} from './utils'

export const enableMaterialsEndpoints = (mock: MockAdapter) => {
  // GET materials (C#)
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/materials$`)).reply(() => {
    const {
      burglengenfeld: {materialsWithRecipes}
    } = mockStore.scenario()
    const materials = materialsWithRecipes.map<Material>(
      ({
        id,
        name,
        createdBy,
        createdOn,
        status,
        type,
        source,
        pxTrendCounters,
        products,
        limsMaterials,
        description,
        origin,
        classification
      }) => {
        return {
          id,
          name,
          createdBy,
          createdOn,
          status,
          type,
          pxTrendCounters,
          source,
          products,
          limsMaterials,
          description,
          origin,
          classification
        }
      }
    )

    return sleepResponse([200, materials])
  })

  // GET material with recipes (C#)
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}$`)).reply((config) => {
    const {burglengenfeld} = mockStore.scenario()
    const materialId = Number((config.url as string).split('/')[4])
    const materialWithRecipes = burglengenfeld.materialsWithRecipes.find(
      (material) => material.id === materialId
    )

    return sleepResponse([200, materialWithRecipes])
  })

  // POST create new material
  mock.onPost(new RegExp(`^/plants/${numberRegEx}/materials$`)).reply((config) => {
    const scenario = mockStore.scenario()
    const {type, source, name, description} = JSON.parse(config.data as string) as AddMaterialDTO
    const newMaterial: Material = {
      createdBy: 'Planner User',
      createdOn: moment.utc().toISOString(),
      id: Date.now(),
      status: Status.Created,
      name,
      type,
      source,
      products: [],
      pxTrendCounters: [],
      limsMaterials: [],
      description,
      origin: {
        source
      }
    }
    const newMaterialWithRecipe: MaterialWithRecipes = {
      ...newMaterial,
      recipes: []
    }
    scenario.burglengenfeld.materialsWithRecipes = [
      ...scenario.burglengenfeld.materialsWithRecipes,
      newMaterialWithRecipe
    ]

    saveScenario(scenario)
    return sleepResponse([201, newMaterial])
  })

  // PATCH edit material
  mock.onPatch(new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}$`)).reply((config) => {
    const materialId = Number((config.url as string).split('/')[4])
    const dto = JSON.parse(config.data as string) as EditMaterialDTO
    const scenario = mockStore.scenario()

    const editedMaterial = scenario.burglengenfeld.materialsWithRecipes.find(
      (material) => material.id === materialId
    )!

    const isProducedInPlantUnchecked =
      (editedMaterial.source === MaterialSource.ProducedInPlant ||
        editedMaterial.source === MaterialSource.BoughtAndProduced) &&
      dto.source === MaterialSource.BoughtFromVendor

    if (isProducedInPlantUnchecked) {
      const recipesIdToDelete = editedMaterial.recipes.map(({id}) => id)
      const operationModesIdsToDelete = scenario.burglengenfeld.assets
        .flatMap(({operationModes}) => operationModes)
        .filter(({recipeId}) => (recipeId ? recipesIdToDelete.includes(recipeId) : false))
        .map(({id}) => id)
      scenario.burglengenfeld.assets = scenario.burglengenfeld.assets.map((asset) => {
        asset.operationModes = asset.operationModes.filter(
          ({id}) => !operationModesIdsToDelete.includes(id)
        )
        return asset
      })
      scenario.burglengenfeld.schedule = {
        ...scenario.burglengenfeld.schedule,
        schedules: Object.values(scenario.burglengenfeld.schedule.schedules).reduce((acc, item) => {
          if (!operationModesIdsToDelete.includes(item.assetOperationModeId)) {
            acc[item.id] = item
          }
          return acc
        }, {})
      }
    }

    scenario.burglengenfeld.materialsWithRecipes = scenario.burglengenfeld.materialsWithRecipes.map(
      (material) => {
        if (material.id === materialId) {
          material = {...material, ...dto, status: Status.Edited}
          if (isProducedInPlantUnchecked) {
            material.recipes = []
            material.products = material.products.filter(({type}) =>
              isMaterialTypeBoughtFromVendor(type)
            )
          }
        }
        return material
      }
    )
    saveScenario(scenario)
    return sleepResponse([200, {}])
  })

  // TODO <HCP-82759>: remove after all add/edit material tabs functionalities
  // are moved to the single save button and global material is moved to BE
  // PATCH edit pxTrend or global material
  const editMaterialResourceRegEx = '(pxtrend-counter|global-material)'
  mock
    .onPatch(
      new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}/${editMaterialResourceRegEx}$`)
    )
    .reply((config) => {
      const materialId = Number((config.url as string).split('/')[4])
      const dto = JSON.parse(config.data as string) as {
        pxTrendCounters?: string[]
        globalMaterialId?: number
      }
      const scenario = mockStore.scenario()

      scenario.burglengenfeld.materialsWithRecipes =
        scenario.burglengenfeld.materialsWithRecipes.map((material) => {
          if (material.id === materialId) {
            material = {...material, ...dto, status: Status.Edited}
          }
          return material
        })
      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // DELETE delete a material
  mock.onDelete(new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}$`)).reply((config) => {
    const scenario = mockStore.scenario()
    const materialId = Number((config.url as string).split('/')[4])
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const materialToDelete = scenario.burglengenfeld.materialsWithRecipes.find(
      (material) => material.id === materialId
    )!

    const recipesIdToDelete = materialToDelete.recipes.map(({id}) => id)

    scenario.burglengenfeld.materialsWithRecipes =
      scenario.burglengenfeld.materialsWithRecipes.filter((material) => material.id !== materialId)

    const operationModesIdsToDelete = scenario.burglengenfeld.assets
      .flatMap(({operationModes}) => operationModes)
      .filter(({recipeId}) => (recipeId ? recipesIdToDelete.includes(recipeId) : false))
      .map(({id}) => id)

    scenario.burglengenfeld.assets = scenario.burglengenfeld.assets.map((asset) => {
      asset.operationModes = asset.operationModes.filter(
        ({id}) => !operationModesIdsToDelete.includes(id)
      )
      return asset
    })

    scenario.burglengenfeld.schedule = {
      ...scenario.burglengenfeld.schedule,
      schedules: Object.values(scenario.burglengenfeld.schedule.schedules).reduce((acc, item) => {
        if (!operationModesIdsToDelete.includes(item.assetOperationModeId)) {
          acc[item.id] = item
        }
        return acc
      }, {})
    }

    saveScenario(scenario)
    return sleepResponse([200, {}])
  })

  // GET material classification metadata
  mock.onGet(new RegExp('^/plants/materials/material-classification-metadata$')).reply(() => {
    const {
      burglengenfeld: {classificationMetadata}
    } = mockStore.scenario()

    return sleepResponse([200, classificationMetadata])
  })

  // POST create a recipe on material
  mock
    .onPost(new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}/recipes$`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const materialId = Number((config.url as string).split('/')[4])
      const {name} = JSON.parse(config.data as string) as RecipeDTO

      const mainMaterialName = scenario.burglengenfeld.materialsWithRecipes.find(
        (material) => material.id === materialId
      )?.name as string

      const newRecipe: Recipe = {
        id: Date.now(),
        name,
        mainMaterialId: materialId,
        status: Status.Created,
        // BE always respond with one component of Output type
        components: [
          {
            id: Date.now(),
            type: RecipeComponentType.Output,
            fraction: 1,
            material: {
              id: materialId,
              name: mainMaterialName,
              pxTrendCounters: []
            }
          }
        ]
      }

      scenario.burglengenfeld.materialsWithRecipes =
        scenario.burglengenfeld.materialsWithRecipes.map((material) => {
          if (material.id === materialId) {
            material.recipes = [...material.recipes, newRecipe]
          }
          return material
        })
      saveScenario(scenario)
      return sleepResponse([201, newRecipe])
    })

  // PATCH edit a recipe on material
  mock
    .onPatch(
      new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}/recipes/${numberRegEx}/name$`)
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const materialId = Number((config.url as string).split('/')[4])
      const recipeId = Number((config.url as string).split('/')[6])

      const {name} = JSON.parse(config.data as string) as RecipeDTO
      const mainMaterial = scenario.burglengenfeld.materialsWithRecipes.find(
        (material) => material.id === materialId
      ) as MaterialWithRecipes
      const prevRecipe = mainMaterial.recipes.find((recipe) => recipe.id === recipeId) as Recipe
      const editedRecipe: Recipe = {...prevRecipe, name, status: Status.Edited}

      scenario.burglengenfeld.materialsWithRecipes =
        scenario.burglengenfeld.materialsWithRecipes.map((material) => {
          if (material.id === materialId) {
            material.recipes = material.recipes.map((recipe) => {
              if (recipe.id === recipeId) {
                return editedRecipe
              }
              return recipe
            })
          }
          return material
        })

      saveScenario(scenario)
      return sleepResponse([200, editedRecipe])
    })

  // DELETE delete a recipe on material
  mock
    .onDelete(
      new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}/recipes/${numberRegEx}$`)
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const materialId = Number((config.url as string).split('/')[4])
      const recipeId = Number((config.url as string).split('/')[6])

      const operationModesIdsToDelete = scenario.burglengenfeld.assets
        .flatMap(({operationModes}) => operationModes)
        .filter((operationMode) => operationMode.recipeId === recipeId)
        .map(({id}) => id)

      scenario.burglengenfeld.assets = scenario.burglengenfeld.assets.map((asset) => {
        asset.operationModes = asset.operationModes.filter(
          ({id}) => !operationModesIdsToDelete.includes(id)
        )
        return asset
      })
      scenario.burglengenfeld.materialsWithRecipes =
        scenario.burglengenfeld.materialsWithRecipes.map((material) => {
          if (material.id === materialId) {
            material.recipes = material.recipes.filter((recipe) => recipe.id !== recipeId)
          }
          return material
        })

      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // POST add recipe component
  mock
    .onPost(
      new RegExp(
        `^/plants/${numberRegEx}/materials/${numberRegEx}/recipes/${numberRegEx}/components$`
      )
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const mainMaterialId = Number((config.url as string).split('/')[4])
      const recipeId = Number((config.url as string).split('/')[6])
      const {
        materialId: componentMaterialId,
        type,
        fraction
      } = JSON.parse(config.data as string) as RecipeComponentDTO
      const componentMaterialName = scenario.burglengenfeld.materialsWithRecipes.find(
        (material) => material.id === componentMaterialId
      )?.name as string

      const newRecipeComponent: RecipeComponent = {
        id: Date.now(),
        type,
        fraction,
        material: {id: componentMaterialId, name: componentMaterialName, pxTrendCounters: []}
      }

      scenario.burglengenfeld.materialsWithRecipes =
        scenario.burglengenfeld.materialsWithRecipes.map((material) => {
          if (material.id === mainMaterialId) {
            material.recipes = material.recipes.map((recipe) => {
              if (recipe.id === recipeId) {
                recipe.components.push(newRecipeComponent)
              }
              return recipe
            })
          }
          return material
        })
      saveScenario(scenario)

      return sleepResponse([201, newRecipeComponent])
    })

  // PATCH edit recipe component fraction
  mock
    .onPatch(
      new RegExp(
        `^/plants/${numberRegEx}/materials/${numberRegEx}/recipes/${numberRegEx}/components/${numberRegEx}/fraction$`
      )
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const mainMaterialId = Number((config.url as string).split('/')[4])
      const recipeId = Number((config.url as string).split('/')[6])
      const recipeComponentId = Number((config.url as string).split('/')[8])
      const {fraction} = JSON.parse(config.data as string) as Pick<RecipeComponentDTO, 'fraction'>

      scenario.burglengenfeld.materialsWithRecipes =
        scenario.burglengenfeld.materialsWithRecipes.map((material) => {
          if (material.id === mainMaterialId) {
            material.recipes = material.recipes.map((recipe) => {
              if (recipe.id === recipeId) {
                recipe.components = recipe.components.map((component) => {
                  if (component.id === recipeComponentId) {
                    component.fraction = fraction
                  }
                  return component
                })
              }
              return recipe
            })
          }
          return material
        })
      saveScenario(scenario)

      return sleepResponse([200, {}])
    })

  // PATCH edit recipe component material
  mock
    .onPatch(
      new RegExp(
        `^/plants/${numberRegEx}/materials/${numberRegEx}/recipes/${numberRegEx}/components/${numberRegEx}/material$`
      )
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const mainMaterialId = Number((config.url as string).split('/')[4])
      const recipeId = Number((config.url as string).split('/')[6])
      const recipeComponentId = Number((config.url as string).split('/')[8])
      const {materialId} = JSON.parse(config.data as string) as Pick<
        RecipeComponentDTO,
        'materialId'
      >
      const componentMaterialName = scenario.burglengenfeld.materialsWithRecipes.find(
        (material) => material.id === materialId
      )?.name as string

      scenario.burglengenfeld.materialsWithRecipes =
        scenario.burglengenfeld.materialsWithRecipes.map((material) => {
          if (material.id === mainMaterialId) {
            material.recipes = material.recipes.map((recipe) => {
              if (recipe.id === recipeId) {
                recipe.components = recipe.components.map((component) => {
                  if (component.id === recipeComponentId) {
                    component.material = {
                      id: materialId,
                      name: componentMaterialName,
                      pxTrendCounters: []
                    }
                  }
                  return component
                })
              }
              return recipe
            })
          }
          return material
        })
      saveScenario(scenario)

      return sleepResponse([200, {}])
    })

  // DELETE delete recipe component
  mock
    .onDelete(
      new RegExp(
        `^/plants/${numberRegEx}/materials/${numberRegEx}/recipes/${numberRegEx}/components/${numberRegEx}$`
      )
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const mainMaterialId = Number((config.url as string).split('/')[4])
      const recipeId = Number((config.url as string).split('/')[6])
      const recipeComponentId = Number((config.url as string).split('/')[8])

      scenario.burglengenfeld.materialsWithRecipes =
        scenario.burglengenfeld.materialsWithRecipes.map((material) => {
          if (material.id === mainMaterialId) {
            material.recipes = material.recipes.map((recipe) => {
              if (recipe.id === recipeId) {
                recipe.components = recipe.components.filter(
                  (component) => component.id !== recipeComponentId
                )
              }
              return recipe
            })
          }
          return material
        })
      saveScenario(scenario)

      return sleepResponse([200, {}])
    })

  // GET all products per plant
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/materials/products$`)).reply(() => {
    const {
      burglengenfeld: {materialsProducts}
    } = mockStore.scenario()

    return sleepResponse([200, materialsProducts])
  })

  // POST (link) product to material
  mock
    .onPost(new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}/products$`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const materialId = Number((config.url as string).split('/')[4])
      const {productId} = JSON.parse(config.data as string) as {productId: number}

      const addedProduct = Object.values(scenario.burglengenfeld.materialsProducts)
        .flat()
        .find((product) => product.id === productId) as Product

      const conflictingMaterial = getMaterialWithAttachedProduct(
        addedProduct.id,
        scenario.burglengenfeld.materialsWithRecipes
      )

      scenario.burglengenfeld.materialsWithRecipes =
        scenario.burglengenfeld.materialsWithRecipes.map((material) => {
          if (material.id === materialId) {
            material.products.push(addedProduct)
          }
          // handling product conflicts (product is already attached to another material)
          if (conflictingMaterial && conflictingMaterial.id === material.id) {
            material.products = material.products.filter(
              (product) => product.id !== addedProduct.id
            )
          }
          return material
        })

      saveScenario(scenario)
      return sleepResponse([201, {}])
    })

  // DELETE (unlink) product from material
  mock
    .onDelete(
      new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}/products/${numberRegEx}$`)
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const materialId = Number((config.url as string).split('/')[4])
      const productId = Number((config.url as string).split('/')[6])

      scenario.burglengenfeld.materialsWithRecipes =
        scenario.burglengenfeld.materialsWithRecipes.map((material) => {
          if (material.id === materialId) {
            material.products = material.products.filter((product) => product.id !== productId)
          }
          return material
        })

      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // GET recipes per assetType
  mock.onGet(new RegExp(`/plants/${numberRegEx}/materials/recipes`)).reply((config) => {
    const {
      burglengenfeld: {materialsWithRecipes}
    } = mockStore.scenario()
    const assetType = config?.params?.assetType as AssetType | undefined
    const allRecipes = materialsWithRecipes.map(({recipes}) => recipes).flat()

    if (assetType === undefined) {
      return sleepResponse([200, allRecipes])
    }

    const assetTypeToMainMaterialTypeMap: Record<AssetType, MaterialType[]> = {
      CementMill: [MaterialType.Cement],
      RawMill: [MaterialType.IntermediateAndFinishedProducts],
      RotaryKiln: [MaterialType.Clinker],
      CoalMill: [MaterialType.Fuels],
      Crusher: [MaterialType.RawMaterials, MaterialType.IntermediateAndFinishedProducts],
      Other: [
        MaterialType.Cement,
        MaterialType.Clinker,
        MaterialType.Fuels,
        MaterialType.RawMaterials,
        MaterialType.IntermediateAndFinishedProducts
      ],
      BaseLoad: []
    }

    const possibleMaterialTypes = assetTypeToMainMaterialTypeMap[assetType]
    const recipes = materialsWithRecipes
      .map((materialWithRecipe) => {
        if (possibleMaterialTypes.includes(materialWithRecipe.type)) {
          return materialWithRecipe.recipes
        }
        return []
      })
      .flat()

    return sleepResponse([200, recipes])
  })

  // GET global materials
  mock.onGet(new RegExp('^/plants/materials/global-materials$')).reply(() => {
    const {
      burglengenfeld: {globalMaterials}
    } = mockStore.scenario()

    return sleepResponse([200, globalMaterials])
  })

  // DELETE delete (unmap) global material from material
  mock
    .onDelete(new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}/global-material$`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const materialId = Number((config.url as string).split('/')[4])
      const materialToUpdate = scenario.burglengenfeld.materialsWithRecipes.find(
        (material) => material.id === materialId
      )!
      delete materialToUpdate.globalMaterialId
      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // GET lims materials for plant
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/materials/lims/materials$`)).reply(() => {
    const {
      burglengenfeld: {limsMaterials}
    } = mockStore.scenario()

    return sleepResponse([200, limsMaterials])
  })

  // POST add (map) lims material to material
  mock
    .onPost(new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}/lims-materials$`))
    .reply((config) => {
      const scenario = mockStore.scenario()

      // Getting the info of lims material which is being assigned
      const {limsMaterialId} = JSON.parse(config.data as string) as {limsMaterialId: number}
      const assignedLimsMaterial = scenario.burglengenfeld.limsMaterials.find(
        (limsMaterial) => limsMaterial.id === limsMaterialId
      )

      // Getting the material where lims material is being assigned and updating it
      const materialId = Number((config.url as string).split('/')[4])
      const materialToUpdate = scenario.burglengenfeld.materialsWithRecipes.find(
        (material) => material.id === materialId
      )!
      materialToUpdate.limsMaterials.push(assignedLimsMaterial as LimsMaterial)

      // Checking if lims material has been already assigned to another material
      const conflictingMaterial = getMaterialWithAssignedLimsMaterial(
        scenario.burglengenfeld.materialsWithRecipes,
        limsMaterialId
      )
      if (conflictingMaterial) {
        // Unmapping lims material from old material
        conflictingMaterial.limsMaterials = conflictingMaterial.limsMaterials.filter(
          (limsMaterial) => limsMaterial.id !== limsMaterialId
        )
      }

      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // DELETE delete (unmap) lims material from material
  mock
    .onDelete(
      new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}/lims-materials/${numberRegEx}$`)
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const materialId = Number((config.url as string).split('/')[4])
      const deletingLimsMaterialId = Number((config.url as string).split('/')[6])

      const materialToUpdate = scenario.burglengenfeld.materialsWithRecipes.find(
        (material) => material.id === materialId
      )!
      materialToUpdate.limsMaterials = materialToUpdate.limsMaterials.filter(
        (limsMaterial) => limsMaterial.id !== deletingLimsMaterialId
      )

      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  return mock
}
