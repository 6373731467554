import {MaterialSource, MaterialBoughtFrom, MaterialType} from '@hconnect/common/types'
import {useMemo} from 'react'

import type {Nullable} from '../../../../../modules/common/types'
import type {AutocompleteOption, ClassificationDTO, MaterialWithRecipes} from '../../../types'

import {CementClassificationInputNames} from '@settings/modules/common/enums'

type MaterialFormClassificationDTO = {materialType: MaterialType} & Nullable<ClassificationDTO>

export type MaterialFormDefaultValues = {
  type: MaterialType | null
  name: string
  description: string
  globalMaterial: AutocompleteOption | null
  source: MaterialSource | null
  boughtFrom: MaterialBoughtFrom
  classification: MaterialFormClassificationDTO
}

export const getEmptyClassification = (
  type: MaterialType = MaterialType.Cement
): MaterialFormClassificationDTO => {
  const emptyClassification = {
    materialType: type
  }
  switch (type) {
    case MaterialType.Cement: {
      Object.values(CementClassificationInputNames).map((cementInputName) => {
        emptyClassification[cementInputName] = null
      })
      emptyClassification[CementClassificationInputNames.IntermediateMillProduct] = 'No'
    }
  }
  return emptyClassification
}

export const getMaterialFormClassification = (
  currentClassification?: ClassificationDTO,
  materialType?: MaterialType
): MaterialFormClassificationDTO => {
  if (!currentClassification) {
    return getEmptyClassification(materialType)
  }

  switch (materialType) {
    case MaterialType.Cement: {
      Object.values(CementClassificationInputNames).map((cementInputName) => {
        currentClassification[cementInputName] = currentClassification[cementInputName] ?? null
      })
    }
  }
  return currentClassification
}

export const useMaterialFormDefaultValues = (
  material: MaterialWithRecipes | undefined,
  // TODO <HCP-82759>: global material will be removed after material classification is implemented
  globalMaterialOptions: AutocompleteOption[]
): MaterialFormDefaultValues => {
  return useMemo(
    () => ({
      type: material?.type ?? null,
      name: material?.name ?? '',
      description: material?.description ?? '',
      globalMaterial: material
        ? globalMaterialOptions.find(
            (globalMaterialOption) => Number(globalMaterialOption.id) === material.globalMaterialId
          ) ?? null
        : null,
      source: material?.origin.source ?? null,
      boughtFrom: material?.origin.boughtFrom ?? MaterialBoughtFrom.Domestic,
      classification: getMaterialFormClassification(material?.classification, material?.type)
    }),
    [material, globalMaterialOptions]
  )
}
