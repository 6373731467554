import {Material, Recipe} from '../../../../types'

export const getAvailableMaterials = (
  allMaterials: Material[],
  recipe: Recipe,
  currentMaterialId?: number
) => {
  const materialIdsUsedInOtherComponents = recipe.components
    .filter((entry) => entry.material.id !== currentMaterialId)
    .map((entry) => entry.material.id)

  return allMaterials.filter((material) => !materialIdsUsedInOtherComponents.includes(material.id))
}
