import {dataTestId} from '@hconnect/uikit'
import {Add} from '@mui/icons-material'
import {Box, Button, Stack} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../../../../permissions'
import {useLimsMaterialsQuery} from '../../../../hooks/queries/useLimsMaterialsQuery'
import {Material, LimsMaterial, NewLimsMaterial} from '../../../../types'
import {getLimsMaterialOptions} from '../helpers'
import {LimsMaterialForm} from '../LimsMaterialForm/LimsMaterialForm'

interface MaterialSettingsLimsMaterialsTabProps {
  material: Material
}

export const MaterialSettingsLimsMaterialsTab: React.FC<MaterialSettingsLimsMaterialsTabProps> = ({
  material
}) => {
  const {t} = useTranslation()
  const canChangeLimsMateialsMapping = usePermission('CHANGE_MATERIALS')
  const [newLimsMaterial, setNewLimsMaterial] = useState<NewLimsMaterial>()
  const {data: plantLimsMaterials} = useLimsMaterialsQuery()

  const shouldLimsMaterialFormsBeDisplayed = !!(
    plantLimsMaterials && plantLimsMaterials.length !== 0
  )

  const isAddLimsCodeButtonEnabled =
    shouldLimsMaterialFormsBeDisplayed && canChangeLimsMateialsMapping && !newLimsMaterial

  const existingLimsMaterials: (LimsMaterial | NewLimsMaterial)[] = [
    ...material.limsMaterials,
    ...(newLimsMaterial ? [newLimsMaterial] : [])
  ]
  return (
    <Stack spacing={3} {...dataTestId('lims_materials_card')}>
      <Box>
        <Button
          variant="text"
          startIcon={<Add />}
          disabled={!isAddLimsCodeButtonEnabled}
          onClick={() => {
            setNewLimsMaterial({code: '', name: ''})
          }}
          {...dataTestId('lims_materials_add_button')}
        >
          {t('materialsSettings.addCode')}
        </Button>
      </Box>
      {shouldLimsMaterialFormsBeDisplayed && (
        <Stack spacing={3}>
          {existingLimsMaterials.map((existingLimsMaterial, index) => (
            <LimsMaterialForm
              key={existingLimsMaterial.id ?? 'new'}
              index={index}
              limsMaterial={existingLimsMaterial}
              setNewLimsMaterial={setNewLimsMaterial}
              limsMaterialOptions={getLimsMaterialOptions(
                plantLimsMaterials,
                material.limsMaterials,
                existingLimsMaterial
              )}
              material={material}
            />
          ))}
        </Stack>
      )}
    </Stack>
  )
}
