import {dataTestId} from '@hconnect/uikit'
import {Card, DataTable, customThemeConstants} from '@hconnect/uikit/src/lib2'
import {Typography, Box} from '@mui/material'
import React, {FC, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'

import {KPI_PARAM_NAME} from '../consts'
import {KpiWithValue, ProcessStage} from '../types/kpi.types'

import {KpiLastResult} from './kpi/KpiLastResult'

const CARD_PADDING_X = 3

export const PrePopulatedKpiContainer: FC<{
  data: ProcessStage[]
  selectedProcessStage: number
}> = ({data, selectedProcessStage}) => {
  return (
    <>
      {data.map((processStage: ProcessStage, index: number) => {
        return (
          <Box hidden={selectedProcessStage !== index} key={processStage.stageCode}>
            <KpiList kpis={processStage.definitionsWithResults} />
          </Box>
        )
      })}
    </>
  )
}

export const KpiList: FC<{kpis: KpiWithValue[]}> = ({kpis}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const [, setSearchParam] = useSearchParams()

  const columns = useMemo(() => {
    return [
      {
        key: 'displayName',
        label: t('kpiCalculationSettings.label.kpiDisplayName'),
        customTemplate: (item: KpiWithValue) => (
          <Typography variant="body1">{item.definition.displayName}</Typography>
        )
      },
      {
        key: 'tagname',
        label: t('kpiCalculationSettings.label.kpiName'),
        customTemplate: (item: KpiWithValue) => (
          <Typography variant="body1">{item.definition.tagname}</Typography>
        )
      },
      {
        key: 'value',
        label: t('kpiCalculationSettings.label.value'),
        customTemplate: (item: KpiWithValue) => {
          return <KpiLastResult language={language} lastResult={item?.lastResult} />
        }
      },
      {
        key: 'unit',
        label: t('kpiCalculationSettings.label.unit'),
        customTemplate: (item: KpiWithValue) => (
          <Typography variant="body1">{item.definition.unit}</Typography>
        )
      }
    ]
  }, [t, language])

  return (
    <Card
      headerContent={`${t('kpiCalculationSettings.label.all')} (${kpis.length})`}
      sx={{px: 0}}
      cardTitleSx={{px: CARD_PADDING_X}}
      {...dataTestId('kpis-list-container')}
    >
      <DataTable<KpiWithValue>
        columns={columns}
        data={kpis ?? []}
        {...dataTestId('kpi-list-table')}
        onRowClick={(_, kpi) => setSearchParam({[KPI_PARAM_NAME]: kpi.definition.tagname})}
        keyExtractor={(kpi) => `${kpi.definition.kpiDefinitionId}`}
        rowSx={() => ({
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: customThemeConstants().palette.contentHover
          }
        })}
      />
    </Card>
  )
}
