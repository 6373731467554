import {dataTestId} from '@hconnect/uikit'
import {Loader} from '@hconnect/uikit/src/lib2'
import {Business as BusinessIcon} from '@mui/icons-material'
import {Stack, Box, Typography, styled} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {usePlantGeneralData} from '../../../../hooks/usePlantGeneralData'
import {useSelectedItem} from '../../../SelectedItemProvider'

const TitleBox = ({header, value, testId}: {header: string; value: string; testId: string}) => {
  return (
    <Stack {...dataTestId(testId)}>
      <Typography variant="caption">{header}</Typography>
      <Typography variant="body1">{value}</Typography>
    </Stack>
  )
}

const BlueCircle = styled(Box)({
  width: '32px',
  height: '32px',
  backgroundColor: 'rgba(217, 233, 249, 1)',
  borderRadius: '50%'
})

export const PlantData = () => {
  const {selectedItem} = useSelectedItem()
  const {data: plantInfo, isLoading, isFetching} = usePlantGeneralData(selectedItem?.id)
  const {t} = useTranslation()

  if (isLoading || isFetching) {
    return <Loader />
  }
  if (!plantInfo) {
    return <div>{t('janusConfig.noData')}</div>
  }

  return (
    <Stack gap={2} {...dataTestId('plant-info-container')}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={1}
        {...dataTestId('plant-name')}
      >
        <BlueCircle display="flex" alignItems="center" justifyContent="center">
          <BusinessIcon sx={{height: '16px', width: '16px'}} color="primary" />
        </BlueCircle>
        <Typography variant="h4">{plantInfo.name}</Typography>
      </Box>
      <Stack gap={3}>
        <Box display="flex" flexDirection="row" gap={2} alignItems="center">
          <TitleBox
            testId="plant-id"
            header={t('janusConfig.plantSetup.sapCode')}
            value={plantInfo.plantId}
          />
          <TitleBox
            testId="plant-site-id"
            header={t('janusConfig.plantSetup.siteId')}
            value={plantInfo.siteId}
          />
        </Box>
        <Stack>
          <Typography {...dataTestId('plant-region')} variant="body2">
            {plantInfo.region}
          </Typography>
          <Typography {...dataTestId('plant-country')} variant="body2">
            {plantInfo.country}
          </Typography>
          <Typography
            {...dataTestId('plant-post-code')}
            variant="body2"
          >{`${plantInfo.postCode} ${plantInfo.city}`}</Typography>
          <Typography {...dataTestId('plant-street')} variant="body2">
            {plantInfo.street}
          </Typography>
          <Typography
            {...dataTestId('plant-location')}
            variant="body2"
          >{`${plantInfo.latitude}, ${plantInfo.longitude}`}</Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
