import {dataTestId} from '@hconnect/uikit'
import {CardBox, Loader} from '@hconnect/uikit/src/lib2'
import {Typography, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useComments} from '../../hooks/useComments'
import {EmptyCommentsList} from '../comments/EmptyCommentsList'

import {ListComment} from './ListComment'

export const AllCommentsView = ({
  onCommentClick,
  selectedDate
}: {
  onCommentClick: (id: string) => void
  selectedDate?: string
}) => {
  const {t} = useTranslation()
  const {data: comments, isLoading, isFetching} = useComments(selectedDate)

  const isLoadingOrIsFetching = isLoading || isFetching
  const showComments = !isLoadingOrIsFetching && comments && comments.janusComments.length > 0

  return (
    <CardBox {...dataTestId('all-comments-container')} height="550px" sx={{overflowY: 'auto'}}>
      <Stack gap={1}>
        <Typography variant="h3">{t('janusConfig.dailyEntry.allComments')}</Typography>
        {showComments ? (
          <Stack gap={3}>
            {comments.janusComments.map((comment) => (
              <ListComment
                onClick={(id) => onCommentClick(id)}
                comment={comment}
                key={`comment-${comment.id}`}
              />
            ))}
          </Stack>
        ) : isLoadingOrIsFetching ? (
          <Loader />
        ) : (
          <EmptyCommentsList />
        )}
      </Stack>
    </CardBox>
  )
}
