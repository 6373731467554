import type {MaterialStorage, AssetsTransitions, AssetLink} from '@hconnect/common/types'

import type {
  AssetsStandardOperationTimes,
  DefaultAssetsOperationTimes,
  AssetStandardOperationTimeValidationData
} from '../../../modules/assets/types'
import type {Schedule} from '../../../modules/common/types'
import {StructureNode} from '../../../modules/common/types'
import {Constraints} from '../../../modules/common/types/contstraints'
import {Latest} from '../../../modules/common/types/latest'
import {
  FunctionsResponse,
  KpiResponse,
  LastResultsResponse,
  SensorsResponse,
  UnitsResponse
} from '../../../modules/kpi-calculation/types'
import type {
  ClassificationMetadataResponse,
  GlobalMaterial,
  LimsMaterial,
  MaterialWithRecipes,
  MaterialsProducts,
  ProductsForecastInfo
} from '../../../modules/materials/types'
import {isStorageWithMaterialGuard} from '../../../modules/storages'
import {MockState} from '../../mockState'

import assets from './assets'
import assetsLinks from './assetsLinks.json'
import assetsStandardOperationTimes from './assetStandardOperationTimes.json'
import assetsTransitions from './assetsTransitions.json'
import classificationMetadata from './classificationMetadata.json'
import comments from './comments.json'
import constraints from './constraints.json'
import cumulatedDailyEntries from './cumulatedDailyEntries.json'
import dailyEntries from './dailyEntries.json'
import dailyEntriesSaved from './dailyEntriesSaved.json'
import defaultAssetOperationTimes from './defaultAssetOperationTimes.json'
import events from './events.json'
import functions from './functions.json'
import globalMaterials from './globalMaterials.json'
import globalMeasurementTypes from './globalMeasurementTypes.json'
import globalMeasurementTypesCategories from './globalMeasurementTypesCategories.json'
import kpiUnits from './kpiUnits.json'
import lastResults from './lastResults.json'
import latestRaw from './latest.json'
import limsMaterials from './limsMaterials.json'
import limsMeasurementTypes from './limsMeasurementTypes.json'
import limsSamplingPoints from './limsSamplingPoints.json'
import products from './materialsProducts.json'
import materialStorage from './materialStorage.json'
import materialsWithRecipes from './materialsWithRecipes.json'
import measurementTypesMappings from './measurementTypesMappings.json'
import monthlyCalendarInfo from './monthlyCalendar.json'
import categoriesTree from './monthlyEntriesCategoriesTree.json'
import plantGeneralData from './plantGeneralData.json'
import processStageKpis from './processStageKpis.json'
import productsSalesForecastInfo from './productsSalesForecastInfo.json'
import resourceDetails from './resourceDetails.json'
import samplingPoints from './samplingPoints.json'
import samplingPointTypes from './samplingPointTypes.json'
import schedule from './schedule.json'
import sensors from './sensors.json'
import standardTimesValidationErrorResponse from './standardTimesValidationErrorResponse.json'
import upmSensors from './umpSensors.json'
import upmEquipment from './upmEquipment.json'
import upmSignal from './upmSiginal.json'
import upmSignalType from './upmSignalType.json'
import upmStructure from './upmStructure.json'
import upmUnits from './upmUnits.json'
import weeklyCalendarInfo from './weeklyCalendar.json'

import {
  CalendarInfo,
  Comments,
  CumulatedKpisResponse,
  DailyKpiInfo,
  Events,
  CategoriesTreeResponse,
  ResourceDetailsResponse
} from '@settings/modules/janus/types'
import {GlobalMeasurementType} from '@settings/modules/measurements/types/GlobalMeasurementType'
import {GlobalMeasurementTypesCategory} from '@settings/modules/measurements/types/GlobalMeasurementTypesCategory'
import {LimsMeasurementType} from '@settings/modules/measurements/types/LimsMeasurementType'
import {MeasurementTypesMapping} from '@settings/modules/measurements/types/MeasurementTypesMapping'
import {
  LimsSamplingPoint,
  SamplingPointType,
  SamplingPoint
} from '@settings/modules/sampling-points/types'
import {SignalDetail} from '@settings/modules/upm/types/signal'

export const mockState: MockState = {
  burglengenfeld: {
    kpiMonthlyCalendar: monthlyCalendarInfo as CalendarInfo,
    kpiWeeklyCalendar: weeklyCalendarInfo as CalendarInfo,
    kpiDailyComments: comments as unknown as Comments,
    kpiDailyEvents: events as unknown as Events,
    kpiDailyEntries: dailyEntries as unknown as DailyKpiInfo[],
    kpiDailyEntriesSaved: dailyEntriesSaved as unknown as DailyKpiInfo[],
    kpiCumulatedDailyEntries: cumulatedDailyEntries as unknown as CumulatedKpisResponse,
    schedule: schedule as Schedule,
    monthlyEntriesCategories: categoriesTree as unknown as CategoriesTreeResponse,
    defaultAssetsOperationTimes: defaultAssetOperationTimes as DefaultAssetsOperationTimes,
    assetsStandardOperationTimes: assetsStandardOperationTimes as AssetsStandardOperationTimes,
    materialsWithRecipes: materialsWithRecipes as MaterialWithRecipes[],
    materialsProducts: products as MaterialsProducts,
    assets,
    latest: latestRaw as Latest,
    assetsTransitions: assetsTransitions as AssetsTransitions,
    assetsLinks: assetsLinks as AssetLink[],
    recipesById: materialsWithRecipes
      .flatMap((material) => material.recipes)
      .reduce((recipesById, recipe) => ({...recipesById, [recipe.id]: recipe}), {}),
    materialsById: materialsWithRecipes.reduce(
      (materialsById, material) => ({...materialsById, [material.id]: material}),
      {}
    ),
    materialStorage: materialStorage as MaterialStorage[],
    storagesWithMaterial: (materialStorage as MaterialStorage[]).filter(isStorageWithMaterialGuard),
    productsSalesForecastInfo: productsSalesForecastInfo as ProductsForecastInfo,
    kpis: processStageKpis as unknown as KpiResponse,
    standardTimesValidationErrorResponse:
      standardTimesValidationErrorResponse as AssetStandardOperationTimeValidationData,
    validatedPostSTDRequests: {},
    validatedPutSTDRequests: {},
    sensors: sensors as SensorsResponse,
    constraints: constraints as Constraints,
    functions: functions as FunctionsResponse,
    kpiUnits: kpiUnits as UnitsResponse,
    globalMeasurementTypesCategories:
      globalMeasurementTypesCategories as GlobalMeasurementTypesCategory[],
    globalMeasurementTypes: globalMeasurementTypes as GlobalMeasurementType[],
    limsMeasurementTypes: limsMeasurementTypes as LimsMeasurementType[],
    measurementTypesMappings: measurementTypesMappings as MeasurementTypesMapping[],
    samplingPointsTypes: samplingPointTypes as SamplingPointType[],
    limsSamplingPoints: limsSamplingPoints as LimsSamplingPoint[],
    samplingPoints: samplingPoints as SamplingPoint[],
    lastResults: lastResults as LastResultsResponse,
    upm: {
      structure: upmStructure as any as StructureNode,
      equipment: upmEquipment,
      units: upmUnits,
      // TODO: fix later
      sensors: upmSensors as any as SignalDetail[],
      signal: upmSignal as SignalDetail,
      signalType: upmSignalType,
      plantData: plantGeneralData
    },
    globalMaterials: globalMaterials as GlobalMaterial[],
    limsMaterials: limsMaterials as LimsMaterial[],
    resourceDetails: resourceDetails as ResourceDetailsResponse,
    classificationMetadata: classificationMetadata as unknown as ClassificationMetadataResponse
  }
}
