import {useSettingsQuery} from '../../common/providers/ApiInterface'
import {useUrlParam} from '../../routing'

export const useMaterialStorages = () => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('materialStorage', [plantCode], {
    cacheTime: 0,
    staleTime: 0,
    useErrorBoundary: true,
    // we must override the default onError, otherwise the global eror handler would be invoked but we want
    // errorBoundary on the component level
    onError: undefined
  })
}
