export enum RecipeComponentType {
  Input = 'Input',
  Output = 'Output'
}

export enum ProductForecastStatus {
  Requested = 'Requested',
  Success = 'Success'
}

export enum Feature {
  Stock = 'stock_screen',
  PriceForecast = 'price_forecast_screen',
  DemandForecast = 'demand_forecast_screen',
  Planning = 'planning_screen',
  ElectricityPurchasing = 'electricity_purchasing',
  ControlOperatorScreen = 'control_room_operator_view',
  PlanningComments = 'planning_comments',
  OperatorViewExactElectricityPrices = 'operator_view_exact_electricity_prices',
  OperatorViewColorCodedElectricityPrices = 'operator_view_color_coded_electricity_prices',
  ShutdownAvailability = 'shutdown_availability',
  CostAvoidance = 'cost_avoidance',
  // TODO: to be removed once standardOperationTimes are new fully implemented
  NewStandardOperationTimes = 'newStandardOperationTimes'
}

export enum MaterialSettingsTabs {
  General = 'general',
  Classification = 'classification',
  SapCodes = 'sapCodes',
  LimsCodes = 'limsCodes',
  Recipes = 'recipes',
  PxTrendTags = 'pxTrendTags'
}

export enum CementClassificationInputNames {
  Standard = 'standard',
  Type = 'type',
  Const1 = 'const1',
  Const2 = 'const2',
  Strength1 = 'strength1',
  Strength2 = 'strength2',
  SulphateResistance = 'sulphateResistance',
  SulphurLevel = 'sulphurLevel',
  HeatOfHydration = 'heatOfHydration',
  LowAlkali = 'lowAlkali',
  SpecialCharacteristics = 'specialCharacteristics',
  CategoryForAnnualReport = 'categoryForAnnualReport',
  IntermediateMillProduct = 'intermediateMillProduct',
  MethodOfFinenessDetermination = 'methodOfFinenessDetermination',
  ReferenceMeshSizeForResidue = 'referenceMeshSizeForResidue'
}

export enum ClinkerClassificationInputNames {
  AlkaliContent = 'alkaliContent',
  Grade = 'grade',
  LowHeat = 'lowHeat',
  SulphateResistance = 'sulphateResistance',
  Type = 'type'
}

export enum RawMaterialClassificationInputNames {
  Category = 'category',
  FormSize = 'formSize',
  IsThermallyProcessedClinkerSubstitute = 'isThermallyProcessedClinkerSubstitute',
  ProcessingDegree = 'processingDegree',
  RawMaterialCategory = 'rawMaterialCategory',
  RawMaterialSubCategory = 'rawMaterialSubCategory',
  RawMaterialType = 'rawMaterialType'
}

export enum FuelClassificationInputNames {
  FuelType = 'fuelType',
  FuelCategory = 'fuelCategory',
  FuelSubCategory = 'fuelSubCategory',
  FormSize = 'formSize',
  ProcessingDegree = 'processingDegree'
}

export enum EnhancersForCementClassificationInputNames {
  Category = 'category',
  SubCategory = 'subCategory',
  Brand = 'brand',
  OtherBrand = 'otherBrand'
}

export enum AdditivesForClinkerClassificationInputNames {
  Category = 'category'
}

export enum NonReportedMaterialsClassificationInputNames {}
