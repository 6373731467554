import {dataTestId} from '@hconnect/uikit'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Outlet} from 'react-router-dom'

import {PageLayout, SettingsHelmet, SettingsSkeleton} from '../../common/components'
import {useLatestQuery} from '../../common/hooks/queries/useLatestQuery'
import {RouteName} from '../../routing'
import {useGlobalMaterialsQuery, useMaterialsQuery} from '../hooks'

export const PageMaterialSettings: React.FC = () => {
  const {t} = useTranslation()
  const {data: materials} = useMaterialsQuery()
  const {data: latestData} = useLatestQuery()
  const {data: globalMaterialOptions} = useGlobalMaterialsQuery()

  if (!materials || !latestData || !globalMaterialOptions) {
    return <SettingsSkeleton />
  }

  return (
    <PageLayout
      title={t('materialsSettings.materials')}
      {...dataTestId(`page_${RouteName.MATERIALS_SETTINGS}`)}
    >
      <SettingsHelmet title={t('materialsSettings.pageTitle')} />
      <Outlet />
    </PageLayout>
  )
}
