import {dataTestId as spreadDataTestId} from '@hconnect/uikit/src/common/utils/testingUtils'
import {FormControl, FormHelperText} from '@mui/material'
import {Controller, Control, Path, FieldValues, RegisterOptions} from 'react-hook-form'

import {Checkbox} from './Checkbox'

type ControlledCheckboxAdapterProps = {
  // in case we need parse boolean to Yes/No or similar
  formatValue: (checked: boolean) => string
  parseValue: (value: string) => boolean
}

type ControlledCheckboxBaseProps<T extends FieldValues> = {
  control: Control<T>
  formDataName: Path<T>
  label: string
  disabled?: boolean
  rules?: RegisterOptions
  onChangeCb?: () => void
  'data-test-id'?: string
}

type ControlledCheckboxProps<T extends FieldValues> =
  | (ControlledCheckboxBaseProps<T> & ControlledCheckboxAdapterProps)
  | (ControlledCheckboxBaseProps<T> & Partial<ControlledCheckboxAdapterProps>)

export const ControlledCheckbox = <T extends FieldValues>({
  control,
  formDataName,
  label,
  rules,
  disabled = false,
  formatValue,
  parseValue,
  onChangeCb,
  ['data-test-id']: dataTestId = 'checkbox'
}: ControlledCheckboxProps<T>) => {
  return (
    <Controller
      name={formDataName}
      control={control}
      rules={rules}
      render={({field: {value, onChange}, fieldState: {error}}) => (
        <FormControl>
          <Checkbox
            label={label}
            value={parseValue ? parseValue(value) : value}
            disabled={disabled}
            onChange={(checked) => {
              onChange(formatValue ? formatValue(checked) : checked)
              onChangeCb?.()
            }}
            {...spreadDataTestId(`${dataTestId}`)}
          />
          {error?.message && (
            <FormHelperText error {...spreadDataTestId(`${dataTestId}_error`)}>
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
