import {useCallback} from 'react'
import {UseFormReturn} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

import {getUrl, PLANT_ROUTES, useUrlParam} from '../../../../../modules/routing'
import {MaterialWithRecipes} from '../../../types'
import {MaterialFormDefaultValues} from '../hooks/useMaterialFormDefaultValues'

interface useMaterialFormCancelProps {
  material: MaterialWithRecipes | undefined
  materialForm: UseFormReturn<MaterialFormDefaultValues>
}

export const useMaterialFormCancel = ({
  material,
  materialForm: {reset}
}: useMaterialFormCancelProps) => {
  const plantCode = useUrlParam('plantCode')
  const navigate = useNavigate()

  const isNewMaterial = material === undefined

  return useCallback(() => {
    if (isNewMaterial) {
      return navigate(getUrl(PLANT_ROUTES.MATERIALS.path, {plantCode}))
    }
    reset()
  }, [isNewMaterial, plantCode, navigate, reset])
}
