import {MaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit/src/common/utils/testingUtils'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {ControlledAutocomplete} from '../../../../../modules/common/components'
import {
  ClassificationInputName,
  ClassificationMetadataEntry,
  ClassificationMetadataValueSet
} from '../../../types'
import {MaterialFormDefaultValues} from '../../MaterialDetailsCard/hooks'

interface ClassificationAutocompleteProps<T extends MaterialType> {
  materialType: T
  inputName: ClassificationInputName<T>
  metadata: ClassificationMetadataEntry<T>
  isDisabled: boolean
  onChangeCb?: () => void
}

export const ClassificationAutocomplete = <T extends MaterialType>({
  materialType,
  inputName,
  metadata,
  isDisabled,
  onChangeCb
}: ClassificationAutocompleteProps<T>) => {
  const {t} = useTranslation()
  const {control, watch} = useFormContext<MaterialFormDefaultValues>()

  const validValueSet = metadata.inputs[inputName].valueSets.find(
    (valueSet: ClassificationMetadataValueSet) => {
      // Agreement with BE: for now if valuesShownWnen is undefined, it's the only valid value set
      if (valueSet.valuesShownWhen === undefined) {
        return true
      }
      // Agreement with BE: for now any field can be dependant only on one other field
      const conditionForValueSet = valueSet.valuesShownWhen[0]

      const conditionCurrentFormValue = watch(
        `classification.${conditionForValueSet.inputName}`
      ) as string | null
      return conditionCurrentFormValue === conditionForValueSet.value
    }
  )
  const options = validValueSet?.values || []
  const isInputWithNoOptions = options.length === 0

  return (
    <ControlledAutocomplete
      control={control}
      formDataName={`classification.${inputName}`}
      options={options}
      label={t(`materialsSettings.classificationInputs.${materialType}.${inputName}`)}
      rules={{
        validate: (value) => isInputWithNoOptions || !!value || t('error.required')
      }}
      disabled={isDisabled || isInputWithNoOptions}
      sx={{
        '& .MuiInputBase-root': {
          height: '56px',
          borderRadius: '8px',
          background: (theme) => theme.palette.background.paper,
          boxShadow: 'none'
        }
      }}
      onChangeCb={onChangeCb}
      {...dataTestId(`${materialType}_classification_${inputName}`)}
    />
  )
}
