import {dataTestId} from '@hconnect/uikit'
import {SxProps, Tab, Tabs, Theme, Typography} from '@mui/material'
import {SyntheticEvent, useCallback} from 'react'

export interface HeaderTab {
  value: string
  label: string
  disabled?: boolean
}

interface HeaderTabsProps {
  tabs: HeaderTab[]
  selectedTab: HeaderTab
  onTabChange: (tab: HeaderTab) => void
  testIdPrefix?: string
  sxTabs?: SxProps<Theme>
  sxTab?: SxProps<Theme>
}

export const HeaderTabs: React.FC<HeaderTabsProps> = ({
  tabs,
  selectedTab,
  onTabChange,
  testIdPrefix = 'header',
  sxTabs,
  sxTab
}) => {
  const handleTabChange = useCallback(
    (e: SyntheticEvent, tabValue: string) => {
      const selectedTab = tabs.find((tab) => tab.value === tabValue)
      if (!selectedTab) {
        throw new Error(`Tab with value ${tabValue} not found`)
      }
      onTabChange(selectedTab)
    },
    [onTabChange, tabs]
  )

  return (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
      value={selectedTab.value}
      onChange={handleTabChange}
      sx={sxTabs}
      {...dataTestId(`${testIdPrefix}-tabs`)}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.value}
          value={tab.value}
          disabled={!!tab.disabled}
          label={<Typography>{tab.label}</Typography>}
          sx={sxTab}
          {...dataTestId(`${testIdPrefix}-tab-${tab.value}`)}
        />
      ))}
    </Tabs>
  )
}
