import MockAdapter from 'axios-mock-adapter'

import {sleepResponse} from '@settings/__mock__/mockServices/utils'
import {mockStore} from '@settings/__mock__/mockStore'

export const enableUpmEndpoints = (mock: MockAdapter) => {
  mock.onGet('/upm/settings/plants/0014/sensors?pageSize=100').reply(() => {
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {upm}
    } = scenario
    return sleepResponse([200, upm.sensors])
  })
  mock
    .onGet('upm/settings/areas/11f5449d-7d0b-4000-3396-08dbb03b2402/sensors?pageSize=100')
    .reply(() => {
      const scenario = mockStore.scenario()
      const {
        burglengenfeld: {upm}
      } = scenario
      return sleepResponse([200, upm.sensors])
    })
  mock.onGet('upm/structure/plant/0014?includeEquipment=true').reply(() => {
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {upm}
    } = scenario
    return sleepResponse([200, upm.structure])
  })
  mock.onGet('upm/structure/plant/0014').reply(() => {
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {upm}
    } = scenario
    return sleepResponse([200, upm.structure])
  })
  mock
    .onGet('upm/settings/hierarchy/areas/11f5449d-7d0b-4000-3396-08dbb03b2402/production-lines')
    .reply(() => {
      return sleepResponse([
        200,
        [{id: 'bd469934-6e8f-4293-a114-08dbb03b2407', name: '1', sensorCount: 53}]
      ])
    })
  mock.onGet('upm/equipment/plant/0014?pageSize=1000').reply(() => {
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {upm}
    } = scenario
    return sleepResponse([200, upm.equipment])
  })
  mock.onGet('upm/unit').reply(() => {
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {upm}
    } = scenario
    return sleepResponse([200, upm.units])
  })
  mock.onGet('upm/siginal').reply(() => {
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {upm}
    } = scenario
    return sleepResponse([200, upm.signal])
  })
  mock.onPost('/upm/settings/signals').reply(() => {
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {upm}
    } = scenario
    return sleepResponse([200, upm.signal])
  })
  mock.onGet('upm/signal-type').reply(() => {
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {upm}
    } = scenario
    return sleepResponse([200, upm.signalType])
  })
  return mock
}
