import {Dialog} from '@mui/material'
import React from 'react'

import {invalidateSettingsQuery} from '../../../common/providers'
import {useUrlParam} from '../../../routing'
import {MonthsFilterOptions} from '../../enums'
import {KpiCommentsView} from '../comments/KpiCommentsView'

export const CommentsDialog = ({
  open,
  onClose,
  id,
  name,
  unitName,
  selectedDate,
  groupId,
  periodType
}: {
  open: boolean
  onClose: () => void
  id: string
  name: string
  unitName: string
  groupId: string
  periodType: MonthsFilterOptions
  selectedDate?: string
}) => {
  const plantCode = useUrlParam('plantCode')
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{sx: {minWidth: '500px', minHeight: '360px'}}}
    >
      <KpiCommentsView
        kpiName={name}
        kpiId={id}
        closeComments={onClose}
        unitName={unitName}
        onSuccess={() => {
          invalidateSettingsQuery('resourceDetails', plantCode, groupId, periodType, selectedDate)
        }}
      />
    </Dialog>
  )
}
