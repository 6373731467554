import {dataTestId, useNotification, useTranslation} from '@hconnect/uikit'
import {CardBox, DataTable, customThemeConstants, useBreakPoints} from '@hconnect/uikit/src/lib2'
import {
  ChatBubbleOutline as ChatBubbleOutlineIcon,
  LayersOutlined as LayersOutlinedIcon
} from '@mui/icons-material'
import {Box, Grid, Stack, TextField, Typography, SxProps, Theme} from '@mui/material'
import moment from 'moment'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Controller, useForm, FormProvider} from 'react-hook-form'
import {useSearchParams} from 'react-router-dom'

import {invalidateSettingsQuery} from '../../../common/providers'
import {useUrlParam} from '../../../routing'
import {CalendarRange, SaveButtonState, EditKpiStatus} from '../../enums'
import {getIsValueFieldValid} from '../../helpers/getIsValueFieldValid'
import {useApproveDailyKpi} from '../../hooks/useApproveDailyKpi'
import {useCanEditData} from '../../hooks/useCanEditData'
import {useDailyEntries} from '../../hooks/useDailyEntries'
import {useEditDailyKpi} from '../../hooks/useEditDailyKpi'
import {DailyKpiInfo} from '../../types'

import {CommentsContainer} from './CommentsContainer'
import {DailyEntryHeader} from './DailyEntryHeader'
import {DailyEntryMobileView} from './DailyEntryMobileView'
import {DateSelection} from './DateSeletion'
import {NameColumnView} from './NameColumnView'

export const rowSx = (selected: boolean): SxProps<Theme> => ({
  backgroundColor: selected ? customThemeConstants().palette.contentSelected : 'none',
  '&:hover': {
    backgroundColor: selected
      ? customThemeConstants().palette.contentSelectedHover
      : customThemeConstants().palette.contentHover
  }
})

const useSmallScreen = () => {
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const isTablet = ['md'].includes(screenSizes)
  const smallScreen = isMobile || isTablet

  return {isMobile, isTablet, smallScreen}
}

export const SingleDayView = ({close}: {close: () => void}) => {
  const {isMobile, isTablet, smallScreen} = useSmallScreen()

  const [entries, setEntries] = useState<DailyKpiInfo[] | undefined>()

  const {t} = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams()
  const clearKpiSelection = useCallback(
    () =>
      setSearchParams((params) => {
        params.delete('kpiId')
        return params
      }),
    [setSearchParams]
  )

  const selectedDate = useMemo(() => {
    const date = searchParams.get('date')

    const defaultDate = moment().subtract(1, 'days').toISOString()

    if (!date) {
      setSearchParams((params) => {
        params.set('date', defaultDate)
        return params
      })
    }

    return date ?? defaultDate
  }, [searchParams, setSearchParams])

  const selectedKpiId = useMemo(() => searchParams.get('kpiId'), [searchParams])

  const {data, isLoading, isFetching} = useDailyEntries(selectedDate)

  const canEditData = useCanEditData(selectedDate)

  const [rangeCanBeApproved, setRangeCanBeApproved] = useState<boolean>(false)
  const [rangeAlreadyApproved, setRangeAlreadyApproved] = useState<boolean>(false)

  const [saveButtonState, setSaveButtonState] = useState<SaveButtonState>(SaveButtonState.Disabled)

  const hideTable = smallScreen && selectedKpiId

  const showAllComments = !smallScreen

  const methods = useForm<{entries: DailyKpiInfo[]}>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      entries: []
    }
  })

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: {isDirty, isValid, errors}
  } = methods

  useEffect(() => {
    const newEntries = data?.map((entry, index) => ({
      ...entry,
      index
    }))
    reset({entries: newEntries})
    setEntries(newEntries)
  }, [data, reset])

  useEffect(() => {
    if (selectedDate) {
      if (canEditData) {
        setSaveButtonState(SaveButtonState.Disabled)
      }
    }
  }, [canEditData, selectedDate])

  useEffect(() => {
    if (!canEditData) {
      return
    }
    if (!isValid || !isDirty) {
      setSaveButtonState((prev) => {
        if (prev === SaveButtonState.Saved) {
          return prev
        }
        return SaveButtonState.Disabled
      })
    } else {
      setSaveButtonState((prev) => {
        if (prev === SaveButtonState.Saved && !isDirty) {
          return prev
        }
        return SaveButtonState.Changed
      })
    }
  }, [isValid, isDirty, canEditData])

  const plantCode = useUrlParam('plantCode')

  const {mutate: editDailyKpi} = useEditDailyKpi()

  const {notify} = useNotification()

  const selectedKpi = useMemo(
    () => entries?.find((kpi) => kpi.id === selectedKpiId),
    [entries, selectedKpiId]
  )

  const {mutate: approveDailyKpi} = useApproveDailyKpi()

  const range = useMemo(
    () => (smallScreen ? CalendarRange.Weekly : CalendarRange.Monthly),
    [smallScreen]
  )

  const formattedDate = useMemo(
    () => moment(selectedDate).format('DD.MM.YYYY').toString(),
    [selectedDate]
  )

  const approve = () => {
    approveDailyKpi(
      {plantCode, date: selectedDate},
      {
        onSuccess: (code: number) => {
          if (code !== 1) {
            notify('error', t('error.failedToApprove'))
          } else {
            invalidateSettingsQuery('dailyEntries', plantCode, selectedDate)
            invalidateSettingsQuery('calendarInfo', plantCode, range, selectedDate)
            notify('success', t('success.editReport'))
          }
        },
        onError: () => {
          notify('error', t('error.reportUpdateError', {name: ''}))
        }
      }
    )
  }

  const submit = handleSubmit((data) => {
    if (!data || !isDirty) return

    const newEntries = data.entries.map((entry) => ({
      ...entry,
      version: entry.versions + 1
    }))
    editDailyKpi(
      {plantCode, value: newEntries, date: selectedDate},
      {
        onError: () => reset(),
        onSuccess: (response) => {
          if (
            response.janusDailyKpis.some(
              (kpi) =>
                kpi.status !== EditKpiStatus.Success && kpi.status !== EditKpiStatus.NotUpdated
            )
          ) {
            const errors = response.janusDailyKpis.filter(
              (kpi) =>
                kpi.status !== EditKpiStatus.Success && kpi.status !== EditKpiStatus.NotUpdated
            )
            if (errors) {
              errors.forEach((error) => {
                const invalidKpi = data.entries.find((entry) => entry.id === error.id)
                if (invalidKpi) {
                  setError(`entries.${invalidKpi.index}.value`, {
                    type: 'manual',
                    message: t('error.minValue', {value: 0})
                  })
                }
              })
            }
            notify('error', t('error.reportUpdateError', {name: ''}))
          } else {
            if (selectedKpiId) {
              invalidateSettingsQuery('events', plantCode, selectedKpiId)
            }
            invalidateSettingsQuery('comments', plantCode, selectedDate)
            invalidateSettingsQuery('dailyEntries', plantCode, selectedDate)
            invalidateSettingsQuery('calendarInfo', plantCode, range, selectedDate)
            notify('success', t('success.editReport'))
            setSaveButtonState(SaveButtonState.Saved)
          }
        }
      }
    )
  })

  const columns = [
    {
      key: 'name',
      label: t('janusConfig.dailyEntry.kpiName'),
      sortable: false,
      numeric: false,
      customTemplate: (item: DailyKpiInfo) => (
        <NameColumnView
          name={item.name}
          kpiType={item.kpiType}
          value={item.value}
          unit={item.unit}
        />
      )
    },

    {
      key: 'value',
      label: t('janusConfig.dailyEntry.kpiValue'),
      sortable: false,
      numeric: false,
      customTemplate: (item: DailyKpiInfo) => {
        if (canEditData && !rangeAlreadyApproved) {
          return (
            <Controller
              control={control}
              name={`entries.${item.index}.value`}
              rules={{
                validate: (value) => getIsValueFieldValid(value, item.unit.id, t)
              }}
              render={({field: {ref, value, onChange}}) => {
                const error = errors?.entries?.[item.index]?.value
                const helperText = (error && error.message) || ''
                return (
                  <TextField
                    fullWidth
                    error={!!error}
                    helperText={helperText}
                    onChange={onChange}
                    onClick={(event) => {
                      if (isTablet) {
                        event.stopPropagation()
                      }
                    }}
                    value={value || ''}
                    inputRef={ref}
                    {...dataTestId(`value-text-field-${item.id}`)}
                  />
                )
              }}
            />
          )
        }
        return (
          <Typography {...dataTestId(`value-text-field-disabled-${item.id}`)}>
            {item.value}
          </Typography>
        )
      }
    },
    {
      key: 'versions',
      label: isTablet ? (
        ''
      ) : (
        <Box display="flex" flexDirection="row" gap={1}>
          {t('janusConfig.dailyEntry.versions')}
          <LayersOutlinedIcon fontSize="small" />
        </Box>
      ),

      sortable: false,
      numeric: false,
      customTemplate: (item: DailyKpiInfo) => (
        <>
          {isTablet && <LayersOutlinedIcon />}
          <Typography>{item.versions}</Typography>
        </>
      )
    },
    {
      key: 'comments',
      label: isTablet ? (
        ''
      ) : (
        <Box display="flex" flexDirection="row" gap={1}>
          {t('janusConfig.dailyEntry.comments')}
          <ChatBubbleOutlineIcon fontSize="small" />
        </Box>
      ),
      sortable: false,
      numeric: false,
      customTemplate: (item: DailyKpiInfo) => (
        <>
          {isTablet && <ChatBubbleOutlineIcon />}
          <Typography>{item.comments}</Typography>
        </>
      )
    }
  ]

  return (
    <FormProvider {...methods}>
      <Stack gap={3} alignItems="center">
        <DateSelection
          setSelectedDate={(date) => {
            reset({entries: undefined})
            setSearchParams((params) => {
              params.set('date', date || '')
              return params
            })
          }}
          setRangeCanBeApproved={setRangeCanBeApproved}
          setRangeAlreadyApproved={setRangeAlreadyApproved}
          selectedDate={selectedDate}
          range={range}
          isSingleDayView
          title={t('janusConfig.dailyEntry.singleDay')}
          onBackButtonClick={() => {
            setSearchParams((params) => {
              params.delete('kpiId')
              return params
            })
            close()
          }}
        />
        <Grid container spacing={2} {...dataTestId('daily-entry-container')}>
          {!hideTable && (
            <Grid item xs={smallScreen ? 12 : 8}>
              <CardBox p={2} height="100%" sx={{overflowY: 'auto'}}>
                <Stack gap={3}>
                  <DailyEntryHeader
                    selectedDate={formattedDate}
                    onSaveClick={() => void submit()}
                    onApproveClick={approve}
                    canRangeBeApproved={rangeCanBeApproved}
                    saveButtonState={
                      canEditData && !rangeAlreadyApproved
                        ? saveButtonState
                        : SaveButtonState.Hidden
                    }
                  />
                  {!isMobile ? (
                    <DataTable<DailyKpiInfo>
                      loading={isLoading || isFetching}
                      columns={columns}
                      data={entries || []}
                      keyExtractor={(item: DailyKpiInfo) => item.id}
                      rowSx={(item) => rowSx(selectedKpiId === item.id)}
                      onRowClick={(_, item: DailyKpiInfo) =>
                        setSearchParams((params) => {
                          params.set('kpiId', item.id)
                          return params
                        })
                      }
                      {...dataTestId('daily-entry-table')}
                    />
                  ) : (
                    <DailyEntryMobileView
                      data={entries || []}
                      canSaveData={!!canEditData && !rangeAlreadyApproved}
                      setSelectedKpi={(kpi) =>
                        setSearchParams((params) => {
                          params.set('kpiId', kpi.id)
                          return params
                        })
                      }
                    />
                  )}
                </Stack>
              </CardBox>
            </Grid>
          )}
          <CommentsContainer
            selectedKpi={selectedKpi}
            smallScreen={smallScreen}
            selectedDate={selectedDate}
            plantCode={plantCode}
            clearKpiSelection={clearKpiSelection}
            showAllComments={showAllComments}
          />
        </Grid>
      </Stack>
    </FormProvider>
  )
}
