import React from 'react'
import {Outlet} from 'react-router-dom'

import {LayoutSettings, SettingsSkeleton} from '../../common/components'
import {useLatestQuery} from '../../common/hooks/queries/useLatestQuery'
import {useGlobalMaterialsQuery, useMaterialsQuery} from '../hooks'

import {MaterialsStructure} from './materials-tree/MaterialsStructure'

interface PageMaterialStructureProps {
  isNewMaterial?: boolean
}

export const PageMaterialStructure: React.FC<PageMaterialStructureProps> = ({
  isNewMaterial = false
}) => {
  const {data: materials} = useMaterialsQuery()
  const {data: latestData} = useLatestQuery()
  const {data: globalMaterialOptions} = useGlobalMaterialsQuery()

  if (!materials || !latestData || !globalMaterialOptions) {
    return <SettingsSkeleton />
  }

  return (
    <LayoutSettings
      list={
        <MaterialsStructure
          materials={materials}
          globalMaterialOptions={globalMaterialOptions}
          isNewMaterial={isNewMaterial}
        />
      }
      details={<Outlet />}
    />
  )
}
