import {ProductType, MaterialSource, Product} from '@hconnect/common/types'
import {pickBy} from 'lodash'

export const isMaterialTypeBoughtFromVendor = (materialType: string) =>
  [ProductType.MARERIAL_SAP, ProductType.MATERIAL_MM].includes(materialType as ProductType)

export const getProductsPerMaterialSource = (
  data: Record<ProductType, Product[]>,
  materialSource: MaterialSource
) => {
  switch (materialSource) {
    case MaterialSource.BoughtFromVendor:
      return pickBy(data, (_v, key) => isMaterialTypeBoughtFromVendor(key)) as Record<
        ProductType,
        Product[]
      >
    case MaterialSource.ProducedInPlant:
      return data
    case MaterialSource.BoughtAndProduced:
      return data
    default:
      throw new Error(`BUG: There are no products for material source ${materialSource}`)
  }
}
