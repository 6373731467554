import {MaterialType} from '@hconnect/common/types'

export type UrlParams = Record<
  'plantCode' | 'page' | '*' | 'assetType' | 'assetId' | 'materialType' | 'materialId',
  string | undefined
>

export type SearchParams = typeof searchParams
export const searchParams = {
  materialType: Object.values(MaterialType),
  measurementTypesCategory: [] as string[]
}
