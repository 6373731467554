import {Material, MaterialSource} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Add} from '@mui/icons-material'
import {Box, Button, Stack} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../../../permissions'
import {useMaterialWithRecipesQuery, useMaterialsProductsQuery} from '../../../hooks'
import type {Product} from '../../../types'

import {getAvailableProducts} from './helpers'
import {ProductForm} from './ProductForm'
import {SalesForecastInfo} from './salesForecast'

interface MaterialSettingsProductsTabProps {
  material: Material
}

export const MaterialSettingsProductsTab: React.FC<MaterialSettingsProductsTabProps> = ({
  material
}) => {
  const {t} = useTranslation()
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')

  const {data: materialWithRecipes} = useMaterialWithRecipesQuery(material.id)
  const {data: materialsProducts} = useMaterialsProductsQuery(materialWithRecipes?.source)

  const [newProduct, setNewProduct] = useState<Omit<Product, 'id'> & {id?: number}>()

  if (!materialWithRecipes || !materialsProducts) return null

  const attachedProducts = materialWithRecipes.products
  const allProducts = Object.values(materialsProducts).flat()
  // it shouldn't be possible to attach same product twice
  const allAvailableProducts = getAvailableProducts(allProducts, materialWithRecipes)

  const isMaterialProducedInPlant = material.source === MaterialSource.ProducedInPlant

  const isAddNewProductDisabled =
    Boolean(newProduct) || allAvailableProducts.length === 0 || !canChangeMaterials

  const products: (Pick<Product, 'code' | 'type'> & {id?: number})[] = [
    ...attachedProducts,
    ...(newProduct ? [newProduct] : [])
  ]

  return (
    <Stack spacing={3} {...dataTestId('attached_products')}>
      <Box>
        <Button
          variant="text"
          startIcon={<Add />}
          disabled={isAddNewProductDisabled}
          onClick={() => {
            const [firstProduct] = allAvailableProducts
            if (firstProduct) {
              setNewProduct({
                type: firstProduct.type,
                code: '',
                name: ''
              })
            }
          }}
          {...dataTestId('add_material_code')}
        >
          {t('materialsSettings.addCode')}
        </Button>
      </Box>
      <Stack spacing={3}>
        {products.map((product, index) => (
          <Stack key={product.id ?? 'new'} {...dataTestId('material_product_row')}>
            <ProductForm
              index={index}
              product={product}
              productsByType={materialsProducts}
              material={materialWithRecipes}
              isReadOnly={!canChangeMaterials}
              setNewProduct={setNewProduct}
            />
            {product.id && isMaterialProducedInPlant && (
              <SalesForecastInfo productId={product.id} materialId={materialWithRecipes.id} />
            )}
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
