import {useSelectedItem} from '../../../SelectedItemProvider'
import {ItemDetailsLayout} from '../../ItemDetailsLayout'

import {MappingInfo} from './MappingInfo'
import {PlantData} from './PlantData'

export const General = () => {
  const {selectedItem} = useSelectedItem()

  if (selectedItem?.entityType === 'Plant') {
    return (
      <ItemDetailsLayout>
        <PlantData />
      </ItemDetailsLayout>
    )
  }

  // temporary solution for: https://jira.heidelbergcement.com/browse/HCP-79013
  if (selectedItem?.entityType === 'Equipment' || selectedItem?.entityType === 'Department') {
    return (
      <ItemDetailsLayout>
        <MappingInfo />
      </ItemDetailsLayout>
    )
  }

  return null
}
