import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useMutation} from 'react-query'

import {mutations} from '../../../api/mutations'
import type {ErrorResponse} from '../../common/types'
import {notifyIfErrorMessage} from '../../common/utils/errorHandling'
import {EditedMonthlyKpi} from '../types'

export const useEditMonthlyKpi = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()

  return useMutation<
    EditedMonthlyKpi[],
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.editMonthlyKpi>[0]
  >(mutations.editMonthlyKpi, {
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    }
  })
}
