import {dataTestId} from '@hconnect/uikit'
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Error as ErrorIcon,
  Check as CheckIcon,
  PendingOutlined as PendingOutlinedIcon
} from '@mui/icons-material'
import {Collapse, ListItemButton, ListItemIcon, ListItemText, IconButton, Box} from '@mui/material'
import {useState} from 'react'

import {Status} from '../../enums'
import {Group} from '../../types'

export const CategoryItem = ({
  data,
  level,
  onClick,
  selectedItemId
}: {
  data?: Group
  level: number
  onClick: (item: Group) => void
  selectedItemId?: string
}) => {
  const [expanded, setExpanded] = useState<boolean>(true)

  if (!data) {
    return null
  }

  const isCompleted = [Status.Approved, Status.Saved].includes(data.status)
  const isPending = data.status === Status.PartiallySaved

  const Icon = isCompleted ? (
    <CheckIcon color="success" {...dataTestId('check-icon')} />
  ) : isPending ? (
    <PendingOutlinedIcon color="secondary" {...dataTestId('pending-icon')} />
  ) : (
    <ErrorIcon color="error" {...dataTestId('error-icon')} />
  )

  return (
    <>
      <ListItemButton
        key={data.id}
        selected={data.id === selectedItemId}
        sx={{
          ml: level,
          borderBottom: '1px solid #E8ECF0'
        }}
        onClick={() => onClick(data)}
        {...dataTestId(`category-item-${data.id}`)}
      >
        <ListItemText
          primary={
            <Box display="flex" flexDirection="row" alignContent="center" gap={1}>
              {Icon}
              {data.name}
            </Box>
          }
          primaryTypographyProps={{variant: 'body1'}}
        />
        {data?.groups && data.groups.length > 0 && (
          <ListItemIcon
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              height: '40px',
              width: '40px'
            }}
          >
            <IconButton
              size="medium"
              onClick={(e) => {
                setExpanded((prev) => !prev)
                e.stopPropagation()
              }}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </ListItemIcon>
        )}
      </ListItemButton>
      <Collapse in={expanded} timeout="auto">
        {data.groups?.map((item) => (
          <CategoryItem
            onClick={onClick}
            selectedItemId={selectedItemId}
            key={item.id}
            data={item}
            level={level + 2}
          />
        ))}
      </Collapse>
    </>
  )
}
