import {dataTestId} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {
  SettingsBackupRestoreOutlined as SettingsBackupRestoreOutlinedIcon,
  CheckOutlined as CheckOutlinedIcon
} from '@mui/icons-material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import {LoadingButton, LoadingButtonProps} from '@mui/lab'
import {Alert, Box, Button, Stack, Tab, Tabs, Typography} from '@mui/material'
import React from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'

import {useSelectedItem} from '../SelectedItemProvider'

import {useItemDetails} from './ItemDetailsProvider'
import {PlantStructureTab} from './tabs/tabs.config'

type ItemDetailsButtonShow = {
  onClick: () => void
  hide: false
  disabled?: boolean
  ButtonProps?: Omit<LoadingButtonProps, 'variant' | 'startIcon' | 'onClick' | 'disabled'>
}

type ItemDetailsButtonHide = {
  hide: true
}

type ItemDetailsButtonProps = ItemDetailsButtonShow | ItemDetailsButtonHide

type ItemDetailsLayoutProps = {
  children: React.ReactNode
  hideNav?: boolean
  title?: string
  revertButton?: ItemDetailsButtonProps
  saveButton?: ItemDetailsButtonProps
  titleAction?: {
    label: string
    onClick: () => void
  }
  CardBoxProps?: React.ComponentProps<typeof CardBox>
}

export const ItemDetailsLayout = ({
  children,
  hideNav,
  title,
  revertButton,
  saveButton,
  titleAction,
  CardBoxProps
}: ItemDetailsLayoutProps) => {
  const {t} = useTranslation()
  const {availableTabs, handleTabChange, selectedTab} = useItemDetails()
  const {selectedItem} = useSelectedItem()

  return (
    <CardBox {...dataTestId('janus-plant-structure-details-card')} {...CardBoxProps}>
      <Stack spacing={3}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" minHeight={44}>
          <Stack>
            {titleAction && (
              <Button
                onClick={titleAction.onClick}
                startIcon={<ChevronLeftIcon />}
                variant="text"
                sx={{fontWeight: 500, p: 0.5, pl: 0, justifyContent: 'flex-start', display: 'flex'}}
                size="small"
              >
                {titleAction.label}
              </Button>
            )}
            <Typography variant="h3">{title ?? selectedItem?.entityLabel}</Typography>
          </Stack>
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'flex-start'
            }}
            gap={1}
          >
            {!revertButton?.hide && (
              <LoadingButton
                {...dataTestId('plant-structure-details-revert-button')}
                variant="outlined"
                startIcon={<SettingsBackupRestoreOutlinedIcon />}
                onClick={revertButton?.onClick}
                disabled={revertButton?.disabled || !revertButton?.onClick}
                {...revertButton?.ButtonProps}
              >
                <span>{t('janusConfig.action.revert')}</span>
              </LoadingButton>
            )}

            {!saveButton?.hide && (
              <LoadingButton
                {...dataTestId('plant-structure-details-save-button')}
                variant="contained"
                startIcon={<CheckOutlinedIcon />}
                onClick={saveButton?.onClick}
                disabled={saveButton?.disabled || !saveButton?.onClick}
                {...saveButton?.ButtonProps}
              >
                <span> {t('janusConfig.action.save')}</span>
              </LoadingButton>
            )}
          </Box>
        </Box>
        {!hideNav && (
          <Tabs
            value={availableTabs.includes(selectedTab) ? selectedTab : availableTabs[0]}
            onChange={(event, value) => handleTabChange(value as PlantStructureTab)}
            allowScrollButtonsMobile
            variant="scrollable"
            sx={{paddingBottom: 1}}
          >
            {availableTabs.map((tab) => (
              <Tab
                {...dataTestId(`item-editor-tab${tab}`)}
                key={`details-tab-${tab}`}
                label={<Typography>{t(`janusConfig.${tab}`)}</Typography>}
                value={tab}
              />
            ))}
          </Tabs>
        )}
        <Box>
          <ErrorBoundary FallbackComponent={TabContentFallback}>{children}</ErrorBoundary>
        </Box>
      </Stack>
    </CardBox>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TabContentFallback = ({error, resetErrorBoundary}) => {
  const {t} = useTranslation()

  if (error?.response?.status === 403) {
    return <Alert severity="error">{t('error.accessDeniedDescriptionShort')}</Alert>
  }
  return <Alert severity="error">{t('error.apology')}</Alert>
}
