import {HttpError} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {useMutation} from 'react-query'

import {Api} from '../../../../../api'
import {settingsQueryClient} from '../../../../common/providers'
import type {SignalDetail} from '../../../types/signal'
import {QueryKey as SignalsKey} from '../../SignalList/api/useGetSignals'

import {QueryKey as SignalDetailQueryKey} from './useGetSignalDetail'

export const useUpdateSignal = (signalId: string, successCb?: () => void) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  return useMutation(
    async (signalData: SignalDetail) => {
      const path = `/upm/signal/${signalId}`
      return (await Api.axiosInstance.put(path, signalData)).data
    },
    {
      onSuccess: async (data) => {
        settingsQueryClient.setQueryData([SignalDetailQueryKey, signalId], data)
        await settingsQueryClient.invalidateQueries(['upmStructure'], {exact: false})
        await settingsQueryClient.invalidateQueries([SignalsKey], {exact: false})
        enqueueSnackbar('Signal updated successfully', {
          variant: 'success',
          autoHideDuration: 2500,
          preventDuplicate: true,
          onClick: () => closeSnackbar()
        })
        successCb?.()
      },
      onError: (error: AxiosError<HttpError>) => {
        enqueueSnackbar(`Signal could not be updated: ${error.message}`, {
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          onClick: () => closeSnackbar()
        })
      }
    }
  )
}
