import {useMemo} from 'react'

import {MaterialSettingsTabs} from '../../../../src/modules/common/enums'
import {HeaderTab} from '../../common/components/HeaderTabs'
import {MaterialWithRecipes} from '../types'

import {
  MaterialSettingsGeneralTab,
  MaterialSettingsLimsMaterialsTab,
  MaterialSettingsProductsTab,
  MaterialSettingsPxTrendTab,
  MaterialSettingsRecipesTab,
  MaterialSettingsClassificationTab
} from './materials-tabs'

interface MaterialDetailsCardProps {
  selectedTab: HeaderTab
  selectedMaterial: MaterialWithRecipes | undefined
}

export const MaterialDetailsCardContent: React.FC<MaterialDetailsCardProps> = ({
  selectedTab,
  selectedMaterial
}) => {
  const isNewMaterial = selectedMaterial === undefined
  const selectedTabValue = selectedTab.value as MaterialSettingsTabs

  const TabContentComponent = useMemo(() => {
    switch (selectedTabValue) {
      case MaterialSettingsTabs.General:
        return MaterialSettingsGeneralTab
      case MaterialSettingsTabs.Classification:
        return MaterialSettingsClassificationTab
      case MaterialSettingsTabs.SapCodes:
        return MaterialSettingsProductsTab
      case MaterialSettingsTabs.LimsCodes:
        return MaterialSettingsLimsMaterialsTab
      case MaterialSettingsTabs.Recipes:
        return MaterialSettingsRecipesTab
      case MaterialSettingsTabs.PxTrendTags:
        return MaterialSettingsPxTrendTab
      default:
        throw new Error(`BUG: Unknown tab value: ${selectedTabValue}`)
    }
  }, [selectedTabValue])

  return isNewMaterial ? (
    <MaterialSettingsGeneralTab material={selectedMaterial} />
  ) : (
    <TabContentComponent material={selectedMaterial} />
  )
}
