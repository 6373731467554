import {Box, Stack} from '@mui/material'
import {FC} from 'react'

import {useUrlParam} from '../../../../../routing'
import {useProductsSalesForecastInfoQuery} from '../../../../hooks'
import {useRequestProductSalesForecast} from '../../../../hooks/mutations/products'

import {ForecastStatus} from './ForecastStatus'
import {ForecastStatusSkeleton} from './ForecastStatusSkeleton'

import {ProductForecastInfo} from '@settings/modules/materials/types'

interface SalesForecastInfoProps {
  materialId: number
  productId: number
}

export const SalesForecastInfo: FC<SalesForecastInfoProps> = ({productId, materialId}) => {
  const plantCode = useUrlParam('plantCode')
  const {data: productForecastInfoById} = useProductsSalesForecastInfoQuery(materialId)
  const {mutate: requestSalesForecast, isLoading: isRequestingForecast} =
    useRequestProductSalesForecast()

  if (!productForecastInfoById || isRequestingForecast) {
    return <ForecastStatusSkeleton />
  }

  const productForecastInfo: ProductForecastInfo | undefined = productForecastInfoById[productId]

  return (
    <Stack spacing={2} direction="row">
      <Box sx={{width: ({spacing}) => spacing(2)}} />
      <ForecastStatus
        forecastInfo={productForecastInfo}
        requestNewForecast={() => requestSalesForecast({plantCode, productId, materialId})}
      />
    </Stack>
  )
}
