/**
 * @author Martin Petran
 */
import {TextFieldProps} from '@mui/material'
import {ReactNode} from 'react'
import {type Control, Controller, FieldValues, type Path, RegisterOptions} from 'react-hook-form'

import {submitOnBlurAndEnterProps} from '../../utils'

import {BaseTextField} from './BaseTextField'

interface ControlledTextFieldProps<TField extends FieldValues> {
  control: Control<TField>
  formDataName: Path<TField>
  rules?: RegisterOptions
  label: string
  children?: ReactNode
  TextFieldProps?: TextFieldProps
  onSubmit?: () => Promise<void>
  onChangeCb?: () => void
  'data-test-id'?: string
}

export const ControlledTextField = <TField extends FieldValues>({
  control,
  formDataName,
  rules,
  label,
  children,
  TextFieldProps,
  onSubmit,
  onChangeCb,
  'data-test-id': dataTestId
}: ControlledTextFieldProps<TField>) => (
  <Controller
    control={control}
    name={formDataName}
    rules={rules}
    render={({field: {ref, value, onChange}, fieldState: {invalid, error}}) => (
      <BaseTextField
        data-test-id={dataTestId}
        label={label}
        value={value ?? ''}
        inputRef={ref}
        onChange={(e) => {
          onChange(e)
          onChangeCb?.()
        }}
        helperText={invalid && (error?.message ? error.message : 'Invalid value')}
        error={invalid}
        {...TextFieldProps}
        {...(onSubmit && submitOnBlurAndEnterProps(onSubmit))}
      >
        {children}
      </BaseTextField>
    )}
  />
)
