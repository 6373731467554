import {dataTestId} from '@hconnect/uikit'
import {Add} from '@mui/icons-material'
import {Box, Button, Stack} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../../../permissions'
import {Material} from '../../../types'

import {PxTrendForm} from './PxTrendForm'

interface MaterialSettingsPxTrendTabProps {
  material: Material
}

export const MaterialSettingsPxTrendTab: React.FC<MaterialSettingsPxTrendTabProps> = ({
  material
}) => {
  const {t} = useTranslation()
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')

  const [hasNewPxTrendCounter, setHasNewPxTrendCounter] = useState<boolean>()

  const {pxTrendCounters = []} = material

  const attachedPxTrendCounters = [...pxTrendCounters, ...(hasNewPxTrendCounter ? [''] : [])]

  return (
    <Stack spacing={3} {...dataTestId('attached_products')}>
      <Box>
        <Button
          variant="text"
          startIcon={<Add />}
          disabled={!canChangeMaterials || Boolean(hasNewPxTrendCounter)}
          onClick={() => setHasNewPxTrendCounter(true)}
          {...dataTestId('add_px_trend')}
        >
          {t('materialsSettings.addTag')}
        </Button>
      </Box>
      {attachedPxTrendCounters.map((pxTrendCounter, index) => (
        <PxTrendForm
          key={`${material.id}_${pxTrendCounter}_${index}`}
          index={index}
          pxTrendCounter={pxTrendCounter}
          material={material}
          isReadOnly={!canChangeMaterials}
          setHasNewPxTrendCounter={setHasNewPxTrendCounter}
        />
      ))}
    </Stack>
  )
}
