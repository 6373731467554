import {Status} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Add} from '@mui/icons-material'
import {Box, Button, Stack} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../../../permissions'
import {useMaterialWithRecipesQuery} from '../../../hooks'
import {NewRecipe} from '../../../types'

import {RecipeForm} from './RecipeForm'

import {useUrlParam} from '@settings/modules/routing'

export const MaterialSettingsRecipesTab: React.FC = () => {
  const {t} = useTranslation()
  const [newRecipe, setNewRecipe] = useState<NewRecipe>()
  const materialId = Number(useUrlParam('materialId'))
  const {data: materialWithRecipes} = useMaterialWithRecipesQuery(materialId)

  const canChangeMaterials = usePermission('CHANGE_MATERIALS')

  if (!materialWithRecipes) {
    throw new Error(
      'BUG: material with recipes data should be loaded before rendering this component'
    )
  }
  const recipes = materialWithRecipes.recipes

  const handleAddRecipe = () => {
    setNewRecipe({
      name: '',
      mainMaterialId: materialId,
      components: [],
      status: Status.New
    })
  }
  const shouldDisableAddRecipeButton = Boolean(newRecipe) || !canChangeMaterials

  return (
    <Stack spacing={3} {...dataTestId('recipe_card')}>
      <Box>
        <Button
          variant="text"
          startIcon={<Add />}
          disabled={shouldDisableAddRecipeButton}
          onClick={handleAddRecipe}
          {...dataTestId('add_recipe_button')}
        >
          {t('materialsSettings.addRecipe')}
        </Button>
      </Box>
      {recipes.map((recipe, index) => (
        <RecipeForm
          key={recipe.id}
          index={index}
          recipe={recipe}
          isReadOnly={!canChangeMaterials}
        />
      ))}
      {newRecipe && (
        <RecipeForm
          index={recipes.length}
          key="new"
          recipe={newRecipe}
          setNewRecipe={setNewRecipe}
          isReadOnly={!canChangeMaterials}
        />
      )}
    </Stack>
  )
}
