import {Material} from '../../../../types'

export const getMaterialWithAssignedLimsMaterial = (
  materials: Material[],
  limsMaterialId: number
): Material | undefined => {
  return materials.find((material) =>
    material.limsMaterials.map((limsMaterial) => limsMaterial.id).includes(limsMaterialId)
  )
}
