import {dataTestId, useTranslation} from '@hconnect/uikit'
import {CardBox, Loader} from '@hconnect/uikit/src/lib2'
import {
  LayersOutlined as LayersOutlinedIcon,
  ChatBubbleOutline as ChatBubbleOutlineIcon
} from '@mui/icons-material/'
import {Box, CardContent, Collapse, Stack, TextField, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {Controller, useFormContext} from 'react-hook-form'

import {invalidateSettingsQuery} from '../../../common/providers'
import {useUrlParam} from '../../../routing'
import {MonthsFilterOptions} from '../../enums'
import {flattenLastValue} from '../../helpers/getFlattenLastValue'
import {getIsValueFieldValid} from '../../helpers/getIsValueFieldValid'
import {useResourceDetails} from '../../hooks/useResourceDetails'
import {CommentsRequest, Group} from '../../types'
import {KpiCommentsView} from '../comments/KpiCommentsView'

import {ResourceCardHeader} from './ResourceCardHeader'

export const ResourceCardMobileView = ({
  selectedResource,
  selectedDate
}: {
  selectedResource: Group
  selectedDate?: string
}) => {
  const [open, setOpen] = useState<boolean>(true)

  const {
    data: resources,
    isLoading,
    isFetching
  } = useResourceDetails(selectedResource?.id, MonthsFilterOptions.Month, selectedDate)

  const {id} = selectedResource

  const [selectedReport, setSelectedReport] = useState<CommentsRequest | undefined>()

  const plantCode = useUrlParam('plantCode')

  const handleToggle = () => {
    setOpen(!open)
  }

  const {
    control,
    formState: {errors},
    clearErrors,
    setValue
  } = useFormContext()

  useEffect(() => {
    const newResources = flattenLastValue(resources)
    setValue(`entries.${id}`, newResources)
  }, [setValue, resources, id])

  const {t} = useTranslation()
  const periodType = MonthsFilterOptions.Month

  if (isLoading || isFetching) {
    return (
      <CardBox>
        <Loader />
      </CardBox>
    )
  }

  if (!resources) {
    return (
      <CardBox>
        <ResourceCardHeader title={selectedResource.name} handleToggle={handleToggle} open={open} />
        <Collapse in={open} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography>{t('janusConfig.dailyEntry.noData')}</Typography>
          </CardContent>
        </Collapse>
      </CardBox>
    )
  }

  return (
    <>
      {selectedReport ? (
        <KpiCommentsView
          kpiName={selectedReport.kpiName}
          kpiId={selectedReport.kpiValueId}
          closeComments={() => setSelectedReport(undefined)}
          unitName={selectedReport.unitName}
          onSuccess={() => {
            invalidateSettingsQuery('resourceDetails', plantCode, id, periodType, selectedDate)
          }}
          backButtonText={t('janusConfig.dailyEntry.backTo', {name: selectedResource.name})}
        />
      ) : (
        <CardBox width="100%">
          <ResourceCardHeader
            title={selectedResource.name}
            handleToggle={handleToggle}
            open={open}
            isMobile
          />
          <Collapse in={open} timeout="auto" unmountOnExit>
            <CardContent>
              <Stack gap={2}>
                {resources.map((report, reportIndex) => {
                  const editableValue = report.values[report.values.length - 1]

                  return (
                    <Box
                      key={report.specificKpiId}
                      display="flex"
                      flexDirection="row"
                      gap={1}
                      alignItems="center"
                    >
                      <Box width="70%">
                        <Controller
                          control={control}
                          rules={{
                            validate: (value) => getIsValueFieldValid(value, report.unit.id, t)
                          }}
                          name={`entries.${id}.${reportIndex}.value`}
                          render={({field: {ref, value, onChange}}) => {
                            const error = errors?.entries?.[id]?.[reportIndex]

                            const helperText = (error && error?.value?.message) || ''

                            return (
                              <TextField
                                {...dataTestId(`value-text-field-${editableValue.date}`)}
                                fullWidth
                                InputProps={{inputProps: {min: 0}}}
                                onClick={(event) => event.stopPropagation()}
                                onChange={(e) => {
                                  clearErrors(`entries.${id}.${reportIndex}`)
                                  onChange(e)
                                }}
                                label={report.name}
                                value={value}
                                inputRef={ref}
                                variant="outlined"
                                error={!!error}
                                helperText={helperText}
                              />
                            )
                          }}
                        />
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        sx={{cursor: 'pointer'}}
                        onClick={() =>
                          setSelectedReport({
                            kpiValueId: editableValue.kpiValueId,
                            kpiName: report.name,
                            unitName: report.unit.unitName
                          })
                        }
                      >
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <LayersOutlinedIcon fontSize="small" />
                          {editableValue.version}
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <ChatBubbleOutlineIcon
                            {...dataTestId(`kpi-comments-number-${editableValue.kpiValueId}`)}
                            fontSize="small"
                          />{' '}
                        </Box>
                      </Box>
                      <Typography>{editableValue.comments}</Typography>
                    </Box>
                  )
                })}
              </Stack>
            </CardContent>
          </Collapse>
        </CardBox>
      )}
    </>
  )
}
