import {TFunction} from 'i18next'

import {UnitType} from '../enums'

export const getIsValueFieldValid = (
  value: string | null | undefined,
  type: UnitType,
  t: TFunction
) => {
  {
    if (type === UnitType.Text) {
      return true
    }

    if (value === null || value === undefined || value === '') {
      return true
    }

    const floatValue = parseFloat(value)
    const isNumber = /^[+-]?(\d+(\.\d*)?|\.\d+)$/.test(value)

    if (!isNumber) {
      return t('error.enterValidInput')
    }

    if (type === UnitType.Time) {
      if (!isNumber || floatValue < 0 || floatValue > 24) {
        return t('error.valueInARange', {min: 0, max: 24})
      }
    }

    if (isNumber && floatValue < 0) {
      return t('error.minValue', {value: 0})
    }

    return true
  }
}
