/**
 * @author Martin Petran
 */
import {dataTestId} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box, MenuItem, Typography} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import React from 'react'
import type {
  Control,
  FieldValues,
  Path,
  PathValue,
  UseFormGetValues,
  UseFormSetValue
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {ThresholdSign} from '../../kpi-calculation/types'
import {AutocompleteOption} from '../types/common'

import {ControlledTextField} from './form'
import {ThresholdsPreview} from './ThresholdsPreview'

type FormValues = FieldValues & {unit?: AutocompleteOption | null}

export type ThresholdsConfig<TFormValues extends FormValues, TOptionValue extends string> = {
  label: string
  unit: string | null
  valueName: Path<TFormValues>
  statusName: Path<TFormValues>
  defaultStatus?: TOptionValue | null
  timeToBreachName?: Path<TFormValues> | null
  sign?: ThresholdSign
  disabled?: boolean
}

export type ThresholdsTableProps<TFormValues extends FormValues, TOptionValue extends string> = {
  title: string
  control: Control<TFormValues>
  getValues: UseFormGetValues<TFormValues>
  setValue: UseFormSetValue<TFormValues>
  thresholdsConfig: readonly ThresholdsConfig<TFormValues, TOptionValue>[]
  statusOptions: readonly {value: TOptionValue; label: string; Icon: React.ReactNode}[]
  onSubmit?: () => Promise<void>
  enableThresholdsPreview?: boolean
  CardBoxProps?: React.ComponentProps<typeof CardBox>
}

export const Thresholds = <TFormValues extends FormValues, TOptionValue extends string>({
  title,
  control,
  getValues,
  setValue,
  onSubmit,
  thresholdsConfig,
  statusOptions,
  enableThresholdsPreview = false,
  CardBoxProps
}: ThresholdsTableProps<TFormValues, TOptionValue>) => {
  const {t} = useTranslation()

  const onThresholdValueChangeCb = (
    thresholdValueField: Path<TFormValues>,
    thresholdStatusField: Path<TFormValues>,
    defaultStatus?: TOptionValue | null
  ) => {
    if (getValues(thresholdValueField) === '') {
      setValue(thresholdStatusField, '' as PathValue<TFormValues, Path<TFormValues>>, {
        shouldValidate: true
      })
    }
    if (getValues(thresholdValueField) && !getValues(thresholdStatusField)) {
      setValue(thresholdStatusField, defaultStatus as PathValue<TFormValues, Path<TFormValues>>, {
        shouldValidate: true
      })
    }
  }

  const getUnitLabelFromFormValues = (emptyUnitPlaceholder = '-'): string => {
    const unit = getValues('unit' as Path<TFormValues>)
    if (!unit) return emptyUnitPlaceholder
    if ('label' in unit) return unit.label

    return unit
  }

  const thresholdsPreviewData = thresholdsConfig.map((threshold) => ({
    name: threshold.label,
    value: getValues(threshold.valueName),
    severity: getValues(threshold.statusName),
    sign: threshold.sign,
    unit: threshold.unit || getUnitLabelFromFormValues('')
  }))

  return (
    <CardBox pt={3} px={3} display="flex" flexDirection="column" gap={3} {...CardBoxProps}>
      <Typography variant="h3" mb={1}>
        {title}
      </Typography>
      {enableThresholdsPreview && <ThresholdsPreview thresholds={thresholdsPreviewData} />}
      <Box display="flex" flexDirection="column" gap={2}>
        {thresholdsConfig.map((threshold) => (
          <Grid container key={threshold.label} spacing={2}>
            <Grid
              xs={2}
              display="flex"
              sx={{textAlign: 'right', justifyContent: 'flex-end', alignItems: 'center'}}
            >
              <Typography variant="body1">{threshold.label}</Typography>
            </Grid>
            <Grid xs={3} sx={{minWidth: 160}}>
              <ControlledTextField
                {...dataTestId(`${threshold.label}-value`)}
                formDataName={threshold.valueName}
                label={t('thresholdTable.label.value', {
                  value: threshold.unit || getUnitLabelFromFormValues()
                })}
                control={control}
                rules={{pattern: {value: /^-?\d*\.?\d*$/, message: t('error.enterValidInput')}}}
                onSubmit={onSubmit}
                TextFieldProps={{
                  disabled: threshold.disabled,
                  InputProps: {
                    ...(threshold.sign && {
                      startAdornment: (
                        <span style={{marginTop: 12, marginRight: 4}}>
                          {threshold.sign === ThresholdSign.MoreThan ? '>' : '<'}
                        </span>
                      )
                    })
                  }
                }}
                onChangeCb={
                  threshold?.statusName
                    ? () =>
                        onThresholdValueChangeCb(
                          threshold.valueName,
                          threshold.statusName,
                          threshold.defaultStatus
                        )
                    : undefined
                }
              />
            </Grid>
            {threshold?.statusName && (
              <Grid xs={4}>
                <ControlledTextField
                  {...dataTestId(`${threshold.label}-status`)}
                  formDataName={threshold.statusName}
                  label={t('thresholdTable.label.status')}
                  control={control}
                  rules={{
                    validate: {
                      required: (status) => {
                        if (getValues(threshold.valueName) && !status) {
                          return t('error.required')
                        }
                        if (getValues(threshold.valueName) === '' && status) {
                          return t('error.thresholdRequired')
                        }
                        return true
                      }
                    }
                  }}
                  onSubmit={onSubmit}
                  TextFieldProps={{
                    select: true
                  }}
                >
                  <MenuItem value="">-</MenuItem>
                  {statusOptions?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                        {option.Icon}
                        <span>{option.label}</span>
                      </div>
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
            )}
            <Grid xs>
              {threshold?.timeToBreachName && (
                <ControlledTextField
                  {...dataTestId(`${threshold.label}-time-to-breach`)}
                  formDataName={threshold.timeToBreachName}
                  label={t('thresholdTable.label.timeToBreach')}
                  control={control}
                  onSubmit={onSubmit}
                />
              )}
            </Grid>
          </Grid>
        ))}
      </Box>
    </CardBox>
  )
}
