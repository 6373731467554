/**
 * @author Martin Petran
 */
import {mergeSx} from '@hconnect/uikit/src/lib2'
import {Autocomplete, SxProps, Theme} from '@mui/material'
import {isObject} from 'lodash'
import {KeyboardEvent} from 'react'
import {Controller} from 'react-hook-form'
import type {Control, FieldValues, Path, RegisterOptions} from 'react-hook-form'

import {submitOnBlurAndEnterProps} from '../../../common/utils'

import {BaseTextField} from './BaseTextField'

interface ControlledAutocompleteProps<
  TOption extends {id: string; label: string} | string,
  TField extends FieldValues
> {
  control: Control<TField>
  formDataName: Path<TField>
  options: TOption[]
  label: string
  translateOption?: (option: string) => string
  rules?: RegisterOptions
  disableClearable?: boolean
  disabled?: boolean
  helperText?: string
  onChangeCb?: () => void
  onSubmit?: () => Promise<void>
  onAddCustomValue?: (newValue: string) => void
  sx?: SxProps<Theme>
  'data-test-id'?: string
}

export const ControlledAutocomplete = <
  TOption extends {id: string; label: string} | string,
  TField extends FieldValues
>({
  control,
  formDataName,
  options,
  label,
  translateOption,
  rules,
  disableClearable = true,
  disabled = false,
  helperText,
  onChangeCb,
  onSubmit,
  onAddCustomValue,
  sx,
  'data-test-id': dataTestId
}: ControlledAutocompleteProps<TOption, TField>) => (
  <Controller
    name={formDataName}
    control={control}
    rules={rules}
    render={({field: {ref, onChange, value}, fieldState: {invalid, error}}) => (
      <Autocomplete
        fullWidth
        value={value}
        disabled={disabled}
        disableClearable={disableClearable}
        onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
          onAddCustomValue?.((event.target as HTMLInputElement).value)
        }}
        onChange={(e, data) => {
          onChange(data)
          onChangeCb?.()
        }}
        options={options}
        data-test-id={dataTestId}
        isOptionEqualToValue={(option, value) =>
          isObject(option) && isObject(value) ? option.id === value.id : option === value
        }
        {...(onSubmit && submitOnBlurAndEnterProps(onSubmit))}
        componentsProps={{
          paper: {
            elevation: 12,
            sx(theme) {
              return {
                background: theme.palette.grey[50]
              }
            }
          }
        }}
        sx={mergeSx(
          {
            '& .MuiInputBase-root': {
              height: '52px',
              borderRadius: '4px',
              background: '#fbfbfc',
              boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.06)',
              mb: helperText && 1,
              '&.Mui-focused': {
                background: '#fbfbfc'
              }
            }
          },
          sx
        )}
        renderInput={(params) => {
          if (typeof value === 'string') {
            params.inputProps.value = translateOption ? translateOption(value) : value
          }
          return (
            <BaseTextField
              {...params}
              label={label}
              inputRef={ref}
              helperText={invalid ? (error?.message ? error.message : 'Invalid value') : helperText}
              error={invalid}
            />
          )
        }}
        renderOption={(props, option) => {
          if (typeof option === 'string') {
            return (
              <li {...props} key={option}>
                {translateOption ? translateOption(option) : option}
              </li>
            )
          }
          return (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )
        }}
      />
    )}
  />
)
