import {Card} from '@hconnect/uikit/src/lib2'
import {useState} from 'react'
import {FormProvider} from 'react-hook-form'
import {useIsMutating} from 'react-query'

import {HeaderTab, HeaderTabs} from '../../../common/components/HeaderTabs'
import {MaterialWithRecipes} from '../../types'
import {MaterialDetailsCardContent} from '../MaterialDetailsCardContent'
import {useAvailableHeaderTabs} from '../materials-tabs/hooks'

import {useMaterialForm} from './hooks'
import {MaterialDetailsCardHeader} from './MaterialDetailsCardHeader'

interface MaterialDetailsCardProps {
  material: MaterialWithRecipes | undefined
}

export const MaterialDetailsCard: React.FC<MaterialDetailsCardProps> = ({material}) => {
  // Preventing user access to invalid tabs while saving
  const runningMutations = useIsMutating(['editMaterial'])
  const isMaterialSaving = Boolean(runningMutations)

  const availableTabs = useAvailableHeaderTabs({
    materialWithRecipes: material,
    isLoading: isMaterialSaving
  })

  const [selectedTab, setSelectedTab] = useState<HeaderTab>(availableTabs[0])

  const materialForm = useMaterialForm(material)

  return (
    <FormProvider {...materialForm}>
      <Card
        headerContent={
          <MaterialDetailsCardHeader
            material={material}
            isMaterialSaving={isMaterialSaving}
            selectedTab={selectedTab}
          />
        }
      >
        <HeaderTabs
          tabs={availableTabs}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          testIdPrefix={'material-settings-card'}
          sxTabs={{mb: 3}}
          sxTab={{color: ({palette}) => palette.common.black, fontWeight: 500}}
        />

        <MaterialDetailsCardContent selectedMaterial={material} selectedTab={selectedTab} />
      </Card>
    </FormProvider>
  )
}
