import {LimsMaterial, NewLimsMaterial} from '../../../../types'

export const getLimsMaterialOptions = (
  plantLimsMaterials: LimsMaterial[],
  alreadyMappedLimsMaterials: LimsMaterial[],
  currentOption: LimsMaterial | NewLimsMaterial
): LimsMaterial[] => {
  const alreadyMappedLimsMaterialsIds = alreadyMappedLimsMaterials.map(
    (limsMaterial) => limsMaterial.id
  )
  return plantLimsMaterials.filter(
    (limsMaterial) =>
      !alreadyMappedLimsMaterialsIds.includes(limsMaterial.id) ||
      limsMaterial.id === currentOption.id
  )
}
