import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import {useState} from 'react'

import {useSelectedItem} from '../../../janus/components/SelectedItemProvider'
import {useDeleteSignal} from '../../hooks/useDeleteSignal'
import {SignalListData} from '../../types/signal'

import {useGetSignals} from './api/useGetSignals'

const getSignalPath = (signal: SignalListData): string => {
  const {area, productionLine, department, equipment} = signal
  return `${area.name} > ${productionLine.name} > ${department.name} > ${equipment.name}`
}

export const SignalList = ({
  onDetailClick,
  onAddClick
}: {
  onDetailClick: (signalId: string) => void
  onAddClick: () => void
}) => {
  const {selectedItem} = useSelectedItem()
  const [searchTerm, setSearchTerm] = useState<string>('')
  if (!selectedItem) {
    throw new Error('Selected item is not defined')
  }

  const {mutate: deleteSignal} = useDeleteSignal()
  const {
    data: signals,
    isLoading,
    isFetching
  } = useGetSignals({
    entityType: selectedItem.entityType,
    entityId: selectedItem.id,
    searchTerm
  })

  return (
    <Stack spacing={1} pb={0} px={0}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" pl={3}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            {`${searchTerm ? 'Filtered' : 'All'} (${signals?.length ?? 0})`}
          </Typography>
          <IconButton data-test-id={'upm-add-sensor-button'} color="primary" onClick={onAddClick}>
            <AddIcon />
          </IconButton>
        </Stack>
        <TextField
          label="Filter"
          variant="filled"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Stack>
      {isFetching && !isLoading ? <LinearProgress /> : <div style={{height: '4px'}}></div>}
      <List sx={{maxHeight: 'calc(100vh - 300px)', overflow: 'auto'}}>
        {!isLoading &&
          signals?.map((signal) => (
            <ListItem
              key={`signal-${signal.id}`}
              disablePadding
              divider
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  color="error"
                  // delete is disabled for now, no clear business requirements
                  disabled={!signal.localName?.toUpperCase()?.includes('TEST')}
                  onClick={() => deleteSignal(signal.id)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              }
            >
              <ListItemButton sx={{px: 3}} onClick={() => onDetailClick(signal.id)}>
                <ListItemText primary={signal.localName} secondary={getSignalPath(signal)} />
              </ListItemButton>
            </ListItem>
          ))}

        {isLoading && (
          <Stack sx={{px: 3, py: 2}} spacing={2}>
            {Array(10)
              .fill(0)
              .map((_, i) => (
                <Stack
                  key={`skeleton-${i}`}
                  justifyContent="space-between"
                  alignItems="center"
                  direction="row"
                  spacing={2}
                >
                  <Skeleton variant="rounded" height={42} width={350} />
                  <Skeleton variant="circular" height={25} width={25} />
                </Stack>
              ))}
          </Stack>
        )}
      </List>
    </Stack>
  )
}
