import {dataTestId, useNotification} from '@hconnect/uikit'
import {BackToLink, useBreakPoints} from '@hconnect/uikit/src/lib2'
import {Box, Typography, Stack} from '@mui/material'
import moment from 'moment'
import React, {useEffect, useMemo, useState} from 'react'
import {useForm, FormProvider} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'

import {invalidateSettingsQuery} from '../../../common/providers'
import {useUrlParam} from '../../../routing'
import {EditKpiStatus, MonthsFilterOptions} from '../../enums'
import {useEditMonthlyKpi} from '../../hooks/useEditMonthlyKpi'
import {EditMonthlyKpi, Group, EditResourceDetails, EditedMonthlyKpi} from '../../types'

import {ItemGroupsProvider} from './ItemGroupsContext'
import {MonthlyEntryView} from './MonthlyEntryView'
import {MonthlyHeader} from './MonthlyHeader'

export const MonthlyEntry = () => {
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const [searchParams, setSearchParams] = useSearchParams()

  const [selectedcontextMonthsFilterOptions, setSelectedContextMonthsFilterOptions] =
    useState<MonthsFilterOptions>(MonthsFilterOptions.Month)

  const [selectedResource, setSelectedResource] = useState<Group>()

  const selectedDate = useMemo(() => {
    const date = searchParams.get('date')
    const defaultDate = moment().subtract(1, 'month').toISOString()
    if (!date) {
      setSearchParams((params) => {
        params.set('date', defaultDate)
        return params
      })
    }
    return date ?? defaultDate
  }, [searchParams, setSearchParams])

  const {t} = useTranslation()

  const methods = useForm<{entries: EditResourceDetails}>({
    mode: 'onChange',
    shouldFocusError: false
  })

  const {
    handleSubmit,
    formState: {isDirty, isValid, errors},
    reset,
    setError
  } = methods

  const {mutateAsync: editMonthlyKpi} = useEditMonthlyKpi()

  const plantCode = useUrlParam('plantCode')

  const {notify} = useNotification()

  const submit = handleSubmit((entries) => {
    const data = {...entries}

    const requests: Promise<EditedMonthlyKpi[]>[] = []

    Object.entries(data.entries).forEach(([groupId, resourceDetails]) => {
      const kpis: EditMonthlyKpi[] =
        resourceDetails.map((entry) => {
          const {value, version, date} = entry

          return {
            specificKpiId: entry.specificKpiId,
            version,
            value,
            date
          }
        }) || []

      if (kpis.length > 0) {
        requests.push(
          editMonthlyKpi({groupId: groupId, plantCode, value: kpis, date: kpis[0].date})
        )
      }
    })

    void Promise.allSettled(requests).then((results) => {
      results.forEach((result, index) => {
        if (result.status === 'rejected') {
          notify('error', t('error.reportUpdateError', {name: ''}))
        } else if (result.status === 'fulfilled') {
          const response = result.value
          const groupId = Object.keys(data.entries)[index]
          if (
            response.some(
              (kpi) =>
                kpi.status !== EditKpiStatus.Success && kpi.status !== EditKpiStatus.NotUpdated
            )
          ) {
            notify(
              'error',
              t('error.reportUpdateError', {
                name: data.entries[groupId][0].kpiGroup.name
              })
            )
            response.forEach((kpi, i) => {
              if (kpi.status !== EditKpiStatus.Success && kpi.status !== EditKpiStatus.NotUpdated) {
                if (kpi.status === EditKpiStatus.ValidationFail) {
                  setError(`entries.${groupId}.${i}.value`, {
                    type: 'manual',
                    message: t('error.minValue', {value: 1})
                  })
                } else if (kpi.status === EditKpiStatus.VersionConcurrencyFail) {
                  setError(`entries.${groupId}.${i}.value`, {
                    type: 'manual',
                    message: t('error.someoneAlreadyMadeChanges')
                  })
                }
              }
            })
          } else {
            notify('success', t('success.editReport'))
            invalidateSettingsQuery(
              'resourceDetails',
              plantCode,
              groupId,
              selectedcontextMonthsFilterOptions,
              selectedDate
            )
            invalidateSettingsQuery('categoriesTree', plantCode, selectedDate)
          }
        }
      })
    })
  })

  useEffect(() => reset(), [selectedResource, reset])

  const showTreeContainer = !isMobile || !selectedResource
  const showResourceDetails = selectedResource && selectedcontextMonthsFilterOptions

  const saveEnabled = useMemo(
    () => isDirty && isValid && !errors?.entries,
    [errors?.entries, isDirty, isValid]
  )

  return (
    <ItemGroupsProvider>
      <FormProvider {...methods}>
        <Stack gap={2} alignItems="center" width="100%">
          <Box
            width={isMobile ? '100%' : '95%'}
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            gap={2}
            {...dataTestId('monthly-entry-container')}
          >
            <Stack sx={{width: isMobile ? '100%' : '30%'}}>
              {selectedResource && isMobile && (
                <BackToLink
                  navigate={() => setSelectedResource(undefined)}
                  targetName={t('janusConfig.monthlyEntry.backToGlobalPlantMenu')}
                  target={t('backToGlobalPlantMenu')}
                />
              )}
              <Typography variant="h1">
                {isMobile && selectedResource
                  ? selectedResource?.name
                  : t('janusConfig.monthlyEntry.monthlyEntry')}
              </Typography>
            </Stack>
            <MonthlyHeader
              selectedDate={selectedDate}
              selectedcontextMonthsFilterOptions={selectedcontextMonthsFilterOptions}
              setSelectedContextMonthsFilterOptions={(option) =>
                setSelectedContextMonthsFilterOptions(option)
              }
              onSaveClick={() => void submit()}
              saveEnabled={saveEnabled}
            />
          </Box>
          <MonthlyEntryView
            periodType={selectedcontextMonthsFilterOptions}
            selectedDate={selectedDate}
            selectedResource={selectedResource}
            showDetails={!!showResourceDetails}
            showTreeContainer={showTreeContainer}
            setSelectedResource={setSelectedResource}
            isMobile={isMobile}
          />
        </Stack>
      </FormProvider>
    </ItemGroupsProvider>
  )
}
