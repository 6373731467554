import {ItemDetailsLayout} from '../ItemDetailsLayout'

import {AssetDetails} from '@settings/modules/janus/components/AssetConfig/AssetConfig'
import {useSelectedItem} from '@settings/modules/janus/components/SelectedItemProvider'

export const Layout = () => {
  const {selectedItem} = useSelectedItem()

  return (
    <ItemDetailsLayout revertButton={{hide: true}} saveButton={{hide: true}}>
      {selectedItem?.upmId && <AssetDetails assetId={selectedItem.upmId} />}
    </ItemDetailsLayout>
  )
}
