import {dataTestId, useNotification} from '@hconnect/uikit'
import {customThemeConstants, useBreakPoints} from '@hconnect/uikit/src/lib2'
import {SaveAlt as SaveAltIcon, Check as CheckIcon} from '@mui/icons-material'
import {Box, styled, Button, TextField, MenuItem} from '@mui/material'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'

import {useUrlParam} from '../../../routing'
import {CalendarRange, MonthsFilterOptions} from '../../enums'
import {useApproveMonthlyKpi} from '../../hooks/useApproveMonthlyKpi'
import {CalendarDatePicker} from '../common/CalendarDatePicker'

import {useItemGroups} from './ItemGroupsContext'

const contextMonthsFilterOptions: MonthsFilterOptions[] = [
  MonthsFilterOptions.Month,
  MonthsFilterOptions.Quarter,
  MonthsFilterOptions.HalfYear,
  MonthsFilterOptions.Year
]

const contextMonthsFilterOptionsTranslations = {
  [MonthsFilterOptions.Month]: 'janusConfig.monthlyEntry.lastMonth',
  [MonthsFilterOptions.Quarter]: 'janusConfig.monthlyEntry.last3Months',
  [MonthsFilterOptions.HalfYear]: 'janusConfig.monthlyEntry.halfAYear',
  [MonthsFilterOptions.Year]: 'janusConfig.monthlyEntry.yearToDate'
}

const ButtonStyled = styled(Button)({
  minWidth: '100px',
  height: '52px',
  '&.Mui-disabled': {
    backgroundColor: 'rgba(1, 106, 212, 0.4)',
    color: 'white',
    '.MuiButton-startIcon': {
      color: 'red !important'
    },
    '.MuiButton-iconSizeMedium': {
      color: 'red !important'
    }
  }
})

const Select = styled(TextField)(({theme}) => ({
  width: 200,
  height: 52,
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.25)',
    height: '100%',
    padding: '0 8px', // Adjust padding to fit within 50px height
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent'
    }
  },
  '& .MuiInputLabel-root': {
    color: customThemeConstants().palette.mediumInputBorder
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.common.white
  },
  '& .MuiFilledInput-underline:before': {
    borderBottomColor: 'rgba(255, 255, 255, 0.25)'
  },
  '& .MuiFilledInput-underline:hover:before': {
    borderBottomColor: 'rgba(255, 255, 255, 0.25)'
  },
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: 'rgba(255, 255, 255, 0.25)'
  },
  '& .MuiSelect-icon': {
    color: theme.palette.common.white
  }
}))

export const MonthlyHeader = ({
  selectedDate,
  selectedcontextMonthsFilterOptions,
  setSelectedContextMonthsFilterOptions,
  saveEnabled,
  onSaveClick
}: {
  selectedDate: string
  selectedcontextMonthsFilterOptions: MonthsFilterOptions
  setSelectedContextMonthsFilterOptions: (option: MonthsFilterOptions) => void
  saveEnabled: boolean
  onSaveClick: () => void
}) => {
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const [_, setSearchParams] = useSearchParams()
  const {notify} = useNotification()
  const {mutateAsync: approveMonthlyKpi} = useApproveMonthlyKpi()

  const plantCode = useUrlParam('plantCode')

  const {itemGroups} = useItemGroups()

  const {t} = useTranslation()

  const handleApproveButton = () => {
    const requests: Promise<number>[] = []

    itemGroups.forEach((group) =>
      requests.push(approveMonthlyKpi({groupId: group, plantCode, date: selectedDate}))
    )

    void Promise.allSettled(requests).then((results) => {
      results.forEach((result) => {
        if (result.status === 'rejected') {
          notify('error', t('error.reportUpdateError', {name: ''}))
        } else if (result.status === 'fulfilled') {
          const code = result.value
          if (code !== 1) {
            notify('error', t('error.failedToApprove'))
          } else {
            notify('success', t('success.editReport'))
          }
        }
      })
    })
  }
  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems="center"
      justifyContent="flex-end"
      gap={isMobile ? 2 : 1}
    >
      <Box display="flex" flexDirection="row" alignItems="flex-end" gap={1}>
        <CalendarDatePicker
          selectedDate={selectedDate}
          setSelectedDate={(date) => {
            setSearchParams((params) => {
              params.set('date', date || '')
              return params
            })
          }}
          type={CalendarRange.Monthly}
          fullWidth={false}
          maxDateAllowed={moment().subtract(1, 'month')}
        />
        {isMobile ? (
          <>
            <Button
              type="submit"
              {...dataTestId('mobile-save-button')}
              sx={{color: 'white', height: 52, borderColor: 'rgba(255, 255, 255, 0.13)'}}
              variant="outlined"
              onClick={onSaveClick}
              disabled={!saveEnabled}
            >
              <SaveAltIcon />
            </Button>
            <Button
              {...dataTestId('mobile-approve-button')}
              sx={{color: 'white', height: 52, borderColor: 'rgba(255, 255, 255, 0.13)'}}
              variant="outlined"
              onClick={handleApproveButton}
              disabled={!itemGroups.length}
            >
              <CheckIcon />
            </Button>
          </>
        ) : (
          <Box display="flex" flexDirection="row" gap={1} alignItems="flex-end">
            <Select
              variant="filled"
              label={t('janusConfig.monthlyEntry.contextMonths')}
              {...dataTestId('context-months-filter')}
              value={selectedcontextMonthsFilterOptions}
              onChange={(event) =>
                setSelectedContextMonthsFilterOptions(
                  event.target.value as unknown as MonthsFilterOptions
                )
              }
              SelectProps={{
                renderValue: (value: unknown) =>
                  t(contextMonthsFilterOptionsTranslations[value as MonthsFilterOptions])
              }}
              select
            >
              {contextMonthsFilterOptions.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  {...dataTestId(`context-months-${option}-option`)}
                >
                  {t(contextMonthsFilterOptionsTranslations[option])}
                </MenuItem>
              ))}
            </Select>
            <ButtonStyled
              color={'primary'}
              startIcon={<CheckIcon />}
              variant="contained"
              {...dataTestId('monthly-entry-save-button')}
              onClick={onSaveClick}
              disabled={!saveEnabled}
            >
              {t('janusConfig.monthlyEntry.save')}
            </ButtonStyled>
            <ButtonStyled
              startIcon={<CheckIcon />}
              variant="contained"
              {...dataTestId('approve-button-test-id')}
              onClick={handleApproveButton}
              disabled={!itemGroups.length}
            >
              {t('janusConfig.monthlyEntry.approve')}
            </ButtonStyled>
          </Box>
        )}
      </Box>
    </Box>
  )
}
