import {dataTestId} from '@hconnect/uikit'
import {ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon} from '@mui/icons-material'
import {Box, IconButton, Typography} from '@mui/material'
import React from 'react'

export const ResourceCardHeader = ({
  title,
  open,
  handleToggle
}: {
  isMobile?: boolean
  title: string
  open: boolean
  handleToggle: () => void
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        cursor: 'pointer'
      }}
      onClick={handleToggle}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
        {...dataTestId(`resource-details-title-${title}`)}
      >
        <IconButton>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
        <Typography variant="h4">{title}</Typography>
      </Box>
    </Box>
  )
}
