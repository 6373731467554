import {getPermissionsByUserId} from '@hconnect/apiclient'
import {KilnInfo} from '@hconnect/common/components/kmsStatus'
import type {
  MaterialStorage,
  ProductType,
  AssetResponse,
  AssetsTransitions,
  AssetLink
} from '@hconnect/common/types'
import {AssetType, AssetLinkType} from '@hconnect/common/types'

import type {
  AssetsStandardOperationTimes,
  DefaultAssetsOperationTimes
} from '../modules/assets/types'
import {validateBaseLoadInAssets} from '../modules/assets/utils'
import {validateAssetOperationModesHaveExistingRecipes} from '../modules/assets/utils/validators/assets'
import {validateScheduleItemsHaveExistingOperationModes} from '../modules/common/helpers/schedule'
import type {Schedule, PlantsResponse, StructureNode} from '../modules/common/types'
import {Constraints} from '../modules/common/types/contstraints'
import {Latest} from '../modules/common/types/latest'
import {CalendarRange, MonthsFilterOptions} from '../modules/janus/enums'
import {
  CalendarInfo,
  CategoriesTreeResponse,
  Comments,
  CumulatedKpisResponse,
  DailyKpiInfo,
  Events,
  PlantDetails,
  ResourceDetailsResponse
} from '../modules/janus/types'
import {
  FunctionsResponse,
  KpiResponse,
  LastResultsResponse,
  SensorsResponse,
  UnitsResponse
} from '../modules/kpi-calculation/types'
import type {
  Material,
  MaterialWithRecipes,
  Product,
  ProductsForecastInfo,
  Recipe,
  GlobalMaterial,
  LimsMaterial,
  ClassificationMetadataResponse
} from '../modules/materials/types'
import type {PermissionParsed} from '../modules/permissions/types'

import {Api, plannerDSApiBasePath} from './api'

import {
  fetchSettingsQuery,
  removeSettingsQuery
} from '@settings/modules/common/providers/ApiInterface'
import {measurementsQueries} from '@settings/modules/measurements/api/queries'
import {samplingPointsQueries} from '@settings/modules/sampling-points/api/queries'

export const queries = {
  defaultAssetsOperationTimes: async ({
    assetsIds,
    plantCode
  }: {
    assetsIds: number[]
    plantCode: string
  }): Promise<DefaultAssetsOperationTimes> => {
    const path = `/schedules/default-asset-operation-times/${plantCode}`
    const params: Record<string, unknown> = {
      assetIds: assetsIds.join(',')
    }
    const response = await Api.axiosInstance.get<DefaultAssetsOperationTimes>(path, {params})
    return response.data
  },
  plants: async (): Promise<PlantsResponse> => {
    const path = '/plants'
    const response = await Api.axiosInstance.get<PlantsResponse>(path)
    return response.data
  },
  permissions: async (userId: string): Promise<PermissionParsed[]> => {
    const permissions = await getPermissionsByUserId(Api.axiosInstance)(userId)
    const permissionsParsed: PermissionParsed[] = permissions.map((permission) => ({
      ...permission,
      parsedDataScope: JSON.parse(permission.dataScope) as PermissionParsed['parsedDataScope']
    }))
    return permissionsParsed
  },
  schedule: async ({
    plantCode,
    start,
    end
  }: {
    plantCode: string
    start: string
    end: string
  }): Promise<Schedule> => {
    const path = '/schedules/latest'
    const params: Record<string, string> = {
      start,
      end,
      plantCode: plantCode
    }
    const response = await Api.axiosInstance.get<Schedule>(path, {params})
    const assets = await fetchSettingsQuery('assets', plantCode)
    try {
      validateScheduleItemsHaveExistingOperationModes(response.data.schedules, assets)
    } catch (error) {
      removeSettingsQuery('assets', plantCode)
      throw error
    }
    return response.data
  },
  assetsStandardOperationTimes: async ({
    assetsIds,
    plantCode
  }: {
    assetsIds: number[]
    plantCode: string
  }): Promise<AssetsStandardOperationTimes> => {
    const path = `/plants/${plantCode}/schedules/standard-operation-times`
    const params: Record<string, unknown> = {
      assetIds: assetsIds.join(',')
    }
    const response = await Api.axiosInstance.get<AssetsStandardOperationTimes>(path, {params})
    return response.data
  },
  latest: async (plantCode?: string): Promise<Latest | undefined> => {
    if (plantCode === undefined) return undefined
    const path = `${plannerDSApiBasePath}/latest/${plantCode}`
    const response = await Api.axiosInstance.get<Latest>(path)
    return response.data
  },
  constraints: async (plantCode: string): Promise<Constraints> => {
    const path = `${plannerDSApiBasePath}/optimizer/${plantCode}/constraints`
    const response = await Api.axiosInstance.get<Constraints>(path)
    return response.data
  },
  materials: async (plantCode: string): Promise<Material[]> => {
    const path = `/plants/${plantCode}/materials`
    const response = await Api.axiosInstance.get<Material[]>(path)
    return response.data
  },
  materialWithRecipes: async (
    plantCode: string,
    materialId?: number
  ): Promise<MaterialWithRecipes | undefined> => {
    if (materialId === undefined) return undefined
    const path = `/plants/${plantCode}/materials/${materialId}`
    const response = await Api.axiosInstance.get<MaterialWithRecipes>(path)
    return response.data
  },
  materialsProducts: async (plantCode: string): Promise<Record<ProductType, Product[]>> => {
    const path = `/plants/${plantCode}/materials/products`
    const response = await Api.axiosInstance.get<Record<ProductType, Product[]>>(path)
    return response.data
  },
  materialsRecipes: async ({
    plantCode,
    assetType
  }: {
    plantCode: string
    assetType?: AssetType
  }): Promise<Recipe[]> => {
    const path = `/plants/${plantCode}/materials/recipes`
    const params = assetType ? {assetType} : undefined
    const response = await Api.axiosInstance.get<Recipe[]>(path, {params})
    return response.data
  },
  assets: async (plantCode: string): Promise<AssetResponse[]> => {
    const path = `/plants/${plantCode}/assets`
    const {data} = await Api.axiosInstance.get<AssetResponse[]>(path)
    // checking if base load is correctly added from BE
    validateBaseLoadInAssets(data)
    const recipes = await fetchSettingsQuery('materialsRecipes', {plantCode})

    try {
      validateAssetOperationModesHaveExistingRecipes(data, recipes)
    } catch (error) {
      removeSettingsQuery('materialsRecipes', {plantCode})
      throw error
    }
    return data
  },
  assetsTransitions: async (plantCode: string): Promise<AssetsTransitions> => {
    const path = `/plants/${plantCode}/assets/transitions`
    const response = await Api.axiosInstance.get<AssetsTransitions>(path)
    return response.data
  },
  assetsLinks: async ({
    plantCode,
    assetId,
    fromTypes,
    toTypes
  }: {
    plantCode: string
    assetId: number
    fromTypes?: AssetLinkType[]
    toTypes?: AssetLinkType[]
  }): Promise<AssetLink[]> => {
    const path = `/plants/${plantCode}/assets/${assetId}/links`
    const params: Record<string, unknown> = {
      ...(fromTypes && {fromTypes: fromTypes.join(',')}),
      ...(toTypes && {toTypes: toTypes.join(',')})
    }
    const response = await Api.axiosInstance.get<AssetLink[]>(path, {params})
    return response.data
  },
  materialStorage: async (plantCode: string): Promise<MaterialStorage[]> => {
    const path = `/plants/${plantCode}/material-storage`
    const response = await Api.axiosInstance.get<MaterialStorage[]>(path)
    return response.data
  },
  productsSalesForecastInfo: async (
    plantCode: string,
    materialId: number
  ): Promise<ProductsForecastInfo> => {
    const path = `/plants/${plantCode}/material-demand/${materialId}/demand/prediction/status`
    const response = await Api.axiosInstance.get<ProductsForecastInfo>(path)
    return response.data
  },
  kpis: async (plantCode: string): Promise<KpiResponse> => {
    const path = `/kpieditor/plants/${plantCode}/kpis`
    const response = await Api.axiosInstance.get<KpiResponse>(path)
    return response.data
  },
  units: async (plantCode: string, kpiId: number): Promise<string[]> => {
    const path = `/kpieditor/plants/${plantCode}/kpis/${kpiId}/units`
    const response = await Api.axiosInstance.get<UnitsResponse>(path)
    return response.data || []
  },
  sensors: async (plantCode: string): Promise<SensorsResponse> => {
    const path = `/kpieditor/plants/${plantCode}/sensors`
    const response = await Api.axiosInstance.get<SensorsResponse>(path)
    return response.data
  },
  functions: async (plantCode: string): Promise<FunctionsResponse> => {
    const path = `/kpieditor/plants/${plantCode}/functions`
    const response = await Api.axiosInstance.get<FunctionsResponse>(path)
    return response.data
  },
  lastResults: async (plantCode: string, kpiId: number): Promise<LastResultsResponse> => {
    const path = `/kpieditor/plants/${plantCode}/kpis/${kpiId}/last-results`
    const response = await Api.axiosInstance.get<LastResultsResponse>(path)
    return response.data
  },
  assetsGeneralInfo: async (plantCode: string): Promise<AssetResponse[]> => {
    const path = `/plants/${plantCode}/assets`
    const {data} = await Api.axiosInstance.get<AssetResponse[]>(path)

    return data
  },
  dailyEntries: async (plantCode: string, date?: string): Promise<DailyKpiInfo[]> => {
    const path = `/kpi-result/janus-kpi/${plantCode}/daily/${date}`
    const response = await Api.axiosInstance.get<DailyKpiInfo[]>(path)
    return response.data
  },
  cumulatedKpis: async (plantCode: string, date?: string): Promise<CumulatedKpisResponse> => {
    const path = `/kpi-result/janus-kpi/${plantCode}/cumulated/${date}`
    const response = await Api.axiosInstance.get<CumulatedKpisResponse>(path)
    return response.data
  },
  events: async (plantCode: string, kpiValueId: string): Promise<Events> => {
    const path = `/janus-kpi-events/${plantCode}/events/${kpiValueId}/events`
    const response = await Api.axiosInstance.get<Events>(path)
    return response.data
  },
  comments: async (plantCode: string, date?: string): Promise<Comments> => {
    const path = `/janus-kpi-events/${plantCode}/events/${date}/comments`
    const response = await Api.axiosInstance.get<Comments>(path)
    return response.data
  },
  categoriesTree: async (plantCode: string, date?: string): Promise<CategoriesTreeResponse> => {
    const path = `/janus-monthly-result/janus-kpi/${plantCode}/tree/${date}`
    const response = await Api.axiosInstance.get<CategoriesTreeResponse>(path)
    return response.data
  },
  resourceDetails: async (
    plantCode: string,
    groupId: string,
    periodType: MonthsFilterOptions,
    date?: string
  ): Promise<ResourceDetailsResponse> => {
    const path = `/janus-monthly-result/janus-kpi/${plantCode}/kpi/${groupId}/period/${periodType}/${date}`
    const response = await Api.axiosInstance.get<ResourceDetailsResponse>(path)
    return response.data
  },
  plantDetails: async (plantId?: string): Promise<PlantDetails> => {
    const path = `/upm/plant/${plantId}`
    const response = await Api.axiosInstance.get<PlantDetails>(path)
    return response.data
  },
  calendarInfo: async (
    plantCode: string,
    range: CalendarRange,
    date?: string
  ): Promise<CalendarInfo> => {
    const path = `/kpi-result/janus-kpi/${plantCode}/daily/${date}/calendar-info/${range}`
    const response = await Api.axiosInstance.get<CalendarInfo>(path)
    return response.data
  },
  globalMaterials: async (): Promise<GlobalMaterial[]> => {
    const path = '/plants/materials/global-materials'
    const response = await Api.axiosInstance.get<GlobalMaterial[]>(path)
    return response.data
  },
  limsMaterials: async (plantCode: string): Promise<LimsMaterial[]> => {
    const path = `/plants/${plantCode}/materials/lims/materials`
    const response = await Api.axiosInstance.get<LimsMaterial[]>(path)
    return response.data
  },
  upmStructure: async (
    plantCode: string,
    includeEquipment: boolean,
    product: string | undefined
  ): Promise<StructureNode> => {
    const path = `upm/structure/plant/${plantCode}`
    return (
      await Api.axiosInstance.get<StructureNode>(path, {
        params: {includeEquipment, product}
      })
    ).data
  },
  kilnInfo: async (plantCode: string): Promise<KilnInfo[]> => {
    const path = `/kms/${plantCode}/kilns`
    return (await Api.axiosInstance.get<KilnInfo[]>(path)).data
  },
  classificationMetadata: async (): Promise<ClassificationMetadataResponse> => {
    const path = '/plants/materials/material-classification-metadata'
    return (await Api.axiosInstance.get<ClassificationMetadataResponse>(path)).data
  },
  ...measurementsQueries,
  ...samplingPointsQueries
}

export type Queries = typeof queries
