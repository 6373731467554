import {MaterialType} from '@hconnect/common/types'
import {Navigate} from 'react-router-dom'

import {PLANT_ROUTES, getUrl, useUrlParam} from '@settings/modules/routing'

export const DefaultMaterialTypeRedirect: React.FC = () => {
  const plantCode = useUrlParam('plantCode')

  return (
    <Navigate
      to={getUrl(PLANT_ROUTES.MATERIALS.TYPE.MATERIAL_TYPE.path, {
        plantCode,
        materialType: MaterialType.Cement
      })}
    />
  )
}
