import {MaterialType} from '@hconnect/common/types'
import {Navigate} from 'react-router-dom'

import {useMaterialsQuery} from '../hooks'

import {PLANT_ROUTES, getUrl, useUrlParam} from '@settings/modules/routing'

export const DefaultMaterialIdRedirect: React.FC = () => {
  const plantCode = useUrlParam('plantCode')
  const materialType = useUrlParam('materialType')

  const {data: materialsForType} = useMaterialsQuery(materialType as MaterialType)

  if (!materialsForType) {
    throw new Error('BUG: Materials should be loaded before mounting this component')
  }

  const firstMaterial = materialsForType.at(0)

  if (!firstMaterial) {
    return null
  }

  return (
    <Navigate
      to={getUrl(PLANT_ROUTES.MATERIALS.TYPE.MATERIAL_TYPE.ID.MATERIAL_ID.path, {
        plantCode,
        materialType,
        materialId: String(firstMaterial.id)
      })}
      replace
    />
  )
}
