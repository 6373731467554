import {ExpandLess, ExpandMore} from '@mui/icons-material'
import React from 'react'
import {NodeApi} from 'react-arborist'

import {MaterialTreeNode} from '../../types'

interface MaterialTypeNodeActionsProps {
  node: NodeApi<MaterialTreeNode>
}

export const MaterialTypeNodeActions: React.FC<MaterialTypeNodeActionsProps> = ({node}) => {
  const Icon = node.isOpen ? ExpandLess : ExpandMore
  return <Icon sx={{color: ({palette}) => palette.primary.main}} />
}
