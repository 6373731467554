import {MaterialType} from '@hconnect/common/types'
import {Navigate, Outlet} from 'react-router-dom'

import {PLANT_ROUTES, getUrl, useUrlParam} from '@settings/modules/routing'

/** checks if material type valid
 * if not, redirects to default material type
 */

export const MaterialTypeGuard: React.FC = () => {
  const plantCode = useUrlParam('plantCode')
  const materialType = useUrlParam('materialType')

  const isMaterialTypeValid = Object.values(MaterialType).includes(materialType as MaterialType)

  if (isMaterialTypeValid) {
    return <Outlet />
  }

  return (
    <Navigate
      to={getUrl(PLANT_ROUTES.MATERIALS.TYPE.MATERIAL_TYPE.path, {
        plantCode,
        // redirecitng to default material type
        materialType: MaterialType.Cement
      })}
    />
  )
}
