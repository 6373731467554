import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../api/mutations'
import {invalidateSettingsQuery} from '../../../common/providers/ApiInterface'
import type {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'

export type EditMaterialAttributeParams = typeof mutations.editMaterialAttribute

// TODO <HCP-82759>: remove after all add/edit material tabs functionalities
// are moved to the single save button
export const useEditMaterialAttribute = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<void, AxiosError<ErrorResponse>, Parameters<EditMaterialAttributeParams>[0]>(
    mutations.editMaterialAttribute,
    {
      mutationKey: 'editMaterialAttribute',
      onError: (error) => {
        notifyIfErrorMessage(error.response?.data.message, raiseError, notify)
      },
      onSuccess: () => {
        notify('success', t('success.editMaterial'))
      },
      onSettled: (data, error, variables) => {
        invalidateSettingsQuery('materials', variables.plantCode)
        invalidateSettingsQuery('materialWithRecipes', variables.plantCode, variables.materialId)
      }
    }
  )
}
