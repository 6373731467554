export enum SelectedView {
  'plantSetup' = 0,
  'dailyEntry' = 1,
  'monthlyEntry' = 2,
  'standardReports' = 3
}

export enum Status {
  Empty = 0, // no icon
  Ok = 1, // error icon
  Error = 2, // icon
  PartiallySaved = 3, // error icon
  Saved = 4, // empty circle
  Approved = 5 // success
}

export enum CalendarRange {
  Monthly = 1,
  Weekly = 2
}

export enum EventType {
  Version = 1,
  Comment = 2
}

export enum EditKpiStatus {
  Success = 0,
  VersionConcurrencyFail = 1,
  ValidationFail = 2,
  NotUpdated = 3,
  ReportAlreadyApproved = 4
}

export enum SaveButtonState {
  Disabled = 0,
  Changed = 1,
  Saved = 2,
  Hidden = 3
}

export enum WarningMessage {
  NoDataFor3Days = 1,
  FillDataReminder = 2,
  DataOverDue = 3
}

export enum UnitType {
  Numeric = 1,
  Time = 2,
  Text = 3,
  Percentage = 4,
  Gigajoule = 5
}

export enum MonthsFilterOptions {
  Month = 1,
  Quarter = 2,
  HalfYear = 3,
  Year = 4
}

export enum MonthlyDataType {
  All = 1,
  Autofilled = 2,
  Manual = 3,
  Missing = 4
}

export enum PlantStructureFilterOptions {
  All = 'all',
  Kilns = 'kilns',
  Mills = 'mills',
  Storages = 'storages',
  Janus = 'janus'
}
