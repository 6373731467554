import {ResourceDetailsResponse} from '../types'

export const flattenLastValue = (data: ResourceDetailsResponse | undefined) => {
  if (!data) {
    return data
  }

  return data.map((item) => {
    const lastValue = item.values[item.values.length - 1] || {}
    const {date, version, value} = lastValue
    return {
      ...item,
      date,
      version,
      value,
      values: undefined
    }
  })
}
