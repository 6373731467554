import {ProductType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Alert, NumberLetterSequenceIndicator} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined, Done} from '@mui/icons-material'
import {TextField, MenuItem, Typography, Stack} from '@mui/material'
import React from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {DeleteButton} from '../../../../common/components'
import {useConfirmDialog} from '../../../../common/providers'
import {requiredValidator} from '../../../../common/utils'
import {useUrlParam} from '../../../../routing'
import {
  useMaterialsQuery,
  useDeleteMaterialProductLink,
  useAddMaterialProductLink
} from '../../../hooks'
import {getMaterialWithAttachedProduct} from '../../../materialSelectors'
import type {Product, Material} from '../../../types'

import {getAvailableProducts} from './helpers'
import {ProductCodeAutocomplete} from './ProductCodeAutocomplete'

interface ProductFormProps {
  index: number
  product: Pick<Product, 'code' | 'type'> & {id?: number}
  productsByType: Record<ProductType, Product[]>
  material: Material
  hideRowNumber?: boolean
  setNewProduct?: (product: undefined) => void
  isReadOnly: boolean
}

export const ProductForm: React.FC<ProductFormProps> = ({
  index,
  product,
  productsByType,
  hideRowNumber,
  material,
  setNewProduct,
  isReadOnly
}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {openDialog} = useConfirmDialog()
  const {mutate: addMaterialProduct} = useAddMaterialProductLink()
  const {data: materials} = useMaterialsQuery()
  const {mutate: deleteProduct} = useDeleteMaterialProductLink()

  const productTypes = Object.keys(productsByType) as ProductType[]
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: {isDirty}
  } = useForm<Pick<Product, 'code' | 'type'> & {id?: number}>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: product
  })

  const currentProductType = watch('type')

  const handleDeleteProduct = () => {
    if (!product.id) {
      setNewProduct?.(undefined)
      return
    }
    const productId = product.id
    openDialog({
      title: t('materialsSettings.deleteMaterialCode'),
      description: t('materialsSettings.deleteMaterialCodeConfirmation', {name: product.code}),
      mainAction: {
        text: t('common.delete'),
        color: 'error',
        icon: <DeleteOutlined />,
        onAction: () => {
          deleteProduct({plantCode, materialId: material.id, productId})
        }
      }
    })
  }

  const submit = handleSubmit(({id}) => {
    if (!id || !isDirty) return

    const attachProduct = () =>
      addMaterialProduct(
        {plantCode, materialId: material.id, productId: id},
        {
          onError: () => reset(),
          onSuccess: () => setNewProduct?.(undefined)
        }
      )

    const conflictedMaterial = getMaterialWithAttachedProduct(id, materials)
    if (conflictedMaterial) {
      openDialog({
        title: t('common.conflict'),
        description: t('materialsSettings.productAlreadyAttachedDescription', {
          materialName: conflictedMaterial.name
        }),
        mainAction: {
          text: t('materialsSettings.assignProductCode'),
          icon: <Done />,
          onAction: attachProduct
        },
        onCancel: () => setNewProduct?.(undefined),
        additionalContent: (
          <>
            <Alert
              accentColor="error"
              content={t('materialsSettings.productAlreadyAttachedAdditionalInfo', {
                thisMaterialName: material.name,
                otherMaterialName: conflictedMaterial.name
              })}
            />
            <Typography variant="body1" sx={{mt: 2}}>
              {t('common.whatDoYouWantToDo')}
            </Typography>
          </>
        ),
        testId: 'conflict_product_already_attached'
      })
      return
    }
    attachProduct()
  })
  // getting possible options in autocomplete by product category type
  const validProducts = productsByType[currentProductType]
  const availableProducts = getAvailableProducts(validProducts, material)
  const productsByCode = Object.fromEntries(validProducts.map((product) => [product.code, product]))

  const isAttachedProduct = Boolean(product?.id)

  return (
    <Stack direction="row" alignItems="center" spacing={2} {...dataTestId('product_form')}>
      {!hideRowNumber && <NumberLetterSequenceIndicator numberIndex={index} />}
      {productTypes.length > 1 && (
        <Controller
          control={control}
          name="type"
          rules={requiredValidator(t)}
          render={({field: {ref, value, onChange}}) => (
            <TextField
              sx={{width: (theme) => theme.spacing(24)}}
              variant="outlined"
              select
              label={t('materialsSettings.productCodeType')}
              disabled={isAttachedProduct}
              inputRef={ref}
              value={value}
              onChange={onChange}
              {...dataTestId('product_type_select')}
            >
              {productTypes.map((productType) => (
                <MenuItem
                  key={productType}
                  value={productType}
                  {...dataTestId('product_type_select_item')}
                >
                  {t(`materialsSettings.productTypes.${productType}`)}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      )}
      <Controller
        control={control}
        name="code"
        rules={requiredValidator(t)}
        render={({field: {value, onChange}, fieldState: {error}}) => (
          <ProductCodeAutocomplete
            onChange={(productCode) => {
              const currentProductId = productsByCode[productCode]?.id
              setValue('id', currentProductId)
              onChange(productCode)
            }}
            productCode={value}
            errorMessage={error?.message}
            submit={() => void submit()}
            productOptions={availableProducts}
            productsByCode={productsByCode}
            isDisabled={isAttachedProduct}
          />
        )}
      />
      <DeleteButton
        onClick={handleDeleteProduct}
        disabled={isReadOnly}
        {...dataTestId('delete_product')}
      />
    </Stack>
  )
}
