import {dataTestId} from '@hconnect/uikit'
import {CardBox, Loader} from '@hconnect/uikit/src/lib2'
import {
  LayersOutlined as LayersOutlinedIcon,
  ChatBubbleOutline as ChatBubbleOutlineIcon
} from '@mui/icons-material/'
import {
  Box,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Typography,
  TextField
} from '@mui/material'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {MonthsFilterOptions} from '../../enums'
import {flattenLastValue} from '../../helpers/getFlattenLastValue'
import {getIsValueFieldValid} from '../../helpers/getIsValueFieldValid'
import {getMonthlyDataCanBeEdited} from '../../helpers/getMonthlyDataCanBeEdited'
import {useResourceDetails} from '../../hooks/useResourceDetails'
import {Group, CommentsRequest} from '../../types'

import {CommentsDialog} from './CommentsDialog'
import {ResourceCardHeader} from './ResourceCardHeader'

export const ResourceCard = ({
  selectedResource,
  periodType,
  selectedDate,
  parentId
}: {
  selectedResource: Group
  periodType: MonthsFilterOptions
  selectedDate?: string
  parentId: string
}) => {
  const {id} = selectedResource

  const {
    data: resources,
    refetch,
    isLoading,
    isFetching
  } = useResourceDetails(id, periodType, selectedDate)

  const {
    control,
    formState: {errors},
    clearErrors,
    setValue
  } = useFormContext()

  const mapResources = () => {
    const newResources = flattenLastValue(resources)
    setValue(`entries.${id}`, newResources)
  }

  useEffect(() => {
    mapResources()
  }, [resources, id])

  useEffect(() => {
    void refetch().then(() => {
      mapResources()
    })
  }, [parentId, refetch])

  const {t} = useTranslation()

  const [open, setOpen] = useState<boolean>(true)
  const [selectedReport, setSelectedReport] = useState<CommentsRequest | undefined>()

  const handleToggle = () => {
    setOpen(!open)
  }

  if (isLoading || isFetching) {
    return (
      <CardBox>
        <Loader />
      </CardBox>
    )
  }

  if (!resources) {
    return (
      <CardBox>
        <ResourceCardHeader title={selectedResource.name} handleToggle={handleToggle} open={open} />
        <Collapse in={open} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography>{t('janusConfig.dailyEntry.noData')}</Typography>
          </CardContent>
        </Collapse>
      </CardBox>
    )
  }

  return (
    <>
      <CardBox>
        <ResourceCardHeader title={selectedResource.name} handleToggle={handleToggle} open={open} />
        <Collapse in={open} timeout="auto" unmountOnExit>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('janusConfig.monthlyEntry.dataField')}</TableCell>{' '}
                  {resources[0].values.map((value) => (
                    <TableCell
                      key={`${value.date}-${resources[0].specificKpiId}`}
                      {...dataTestId(
                        `table-head-cell-date-${value.date}-${resources[0].specificKpiId}`
                      )}
                    >
                      {moment(value.date).format('MMMM YYYY').toString()}
                    </TableCell>
                  ))}
                  <TableCell>{''}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resources.map((report, reportIndex) => {
                  const editableValue = report.values[report.values.length - 1]

                  return (
                    <TableRow key={`report-${report.specificKpiId}`}>
                      <TableCell
                        key={`cell-name-${report.specificKpiId}`}
                        {...dataTestId(`cell-name-${report.name}`)}
                      >
                        {`${report.name} (${report.unit.unitName})`}
                      </TableCell>
                      {report.values.map((kpiValue) => (
                        <TableCell
                          {...dataTestId(
                            `report-kpi-value-cell-${report.specificKpiId}-${kpiValue.date}`
                          )}
                          key={`report-kpi-value-cell-${report.specificKpiId}-${kpiValue.date}`}
                        >
                          {getMonthlyDataCanBeEdited(selectedDate, kpiValue.date) ? (
                            <Controller
                              control={control}
                              rules={{
                                validate: (value) => getIsValueFieldValid(value, report.unit.id, t)
                              }}
                              name={`entries.${id}.${reportIndex}.value`}
                              render={({field: {ref, value, onChange}}) => {
                                const error = errors?.entries?.[id]?.[reportIndex]

                                const helperText = (error && error?.value?.message) || ''

                                return (
                                  <TextField
                                    fullWidth
                                    onClick={(event) => event.stopPropagation()}
                                    onChange={(e) => {
                                      clearErrors(`entries.${id}.${reportIndex}`)
                                      onChange(e)
                                    }}
                                    value={value || ''}
                                    InputProps={{inputProps: {min: 0}}}
                                    inputRef={ref}
                                    variant="outlined"
                                    error={!!error}
                                    helperText={helperText}
                                  />
                                )
                              }}
                            />
                          ) : (
                            kpiValue.value || '-'
                          )}
                        </TableCell>
                      ))}
                      <TableCell>
                        <Box
                          display="flex"
                          flexDirection="row"
                          gap={1}
                          sx={{cursor: 'pointer'}}
                          onClick={() =>
                            setSelectedReport({
                              kpiValueId: editableValue.kpiValueId,
                              kpiName: report.name,
                              unitName: report.unit.unitName
                            })
                          }
                        >
                          <Box display="flex" flexDirection="row" alignItems="center">
                            <LayersOutlinedIcon fontSize="small" /> {editableValue.version}
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            {...dataTestId(`kpi-comments-number-${editableValue.kpiValueId}`)}
                          >
                            <ChatBubbleOutlineIcon fontSize="small" /> {editableValue.comments || 0}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </CardContent>
        </Collapse>
      </CardBox>
      {selectedReport && (
        <CommentsDialog
          groupId={id}
          selectedDate={selectedDate}
          periodType={periodType}
          open={!!selectedReport}
          onClose={() => setSelectedReport(undefined)}
          id={selectedReport?.kpiValueId}
          name={selectedReport.kpiName}
          unitName={selectedReport.unitName}
        />
      )}
    </>
  )
}
