import {Grid} from '@mui/material'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'

import {invalidateSettingsQuery} from '../../../common/providers'
import {DailyKpiInfo} from '../../types'
import {KpiCommentsView} from '../comments/KpiCommentsView'

import {AllCommentsView} from './AllCommentsView'

export const CommentsContainer = ({
  selectedKpi,
  smallScreen,
  selectedDate,
  plantCode,
  clearKpiSelection,
  showAllComments
}: {
  selectedKpi: DailyKpiInfo | undefined
  smallScreen: boolean
  selectedDate: string
  plantCode: string
  clearKpiSelection: () => void
  showAllComments: boolean
}) => {
  const {t} = useTranslation()

  const [_, setSearchParams] = useSearchParams()

  if (selectedKpi) {
    return (
      <Grid item xs={smallScreen ? 12 : 4}>
        <KpiCommentsView
          kpiId={selectedKpi.id}
          kpiName={selectedKpi.name}
          unitName={selectedKpi.unit.unitName}
          onSuccess={() => {
            invalidateSettingsQuery('comments', plantCode, selectedDate)
            invalidateSettingsQuery('dailyEntries', plantCode, selectedDate)
          }}
          closeComments={clearKpiSelection}
          backButtonText={
            smallScreen
              ? t('janusConfig.dailyEntry.backTo', {
                  name: moment(selectedDate).format('DD.MM.YYYY').toString()
                })
              : t('janusConfig.dailyEntry.backToAllComments')
          }
        />
      </Grid>
    )
  }

  if (showAllComments) {
    return (
      <Grid item xs={4}>
        <AllCommentsView
          selectedDate={selectedDate}
          onCommentClick={(id) =>
            setSearchParams((params) => {
              params.set('kpiId', id)
              return params
            })
          }
        />
      </Grid>
    )
  }

  return null
}
