import {Skeleton} from '@mui/material'
import React from 'react'

import {usePermission} from '../../../../permissions'
import {useGlobalMaterialsQuery} from '../../../hooks'
import {MaterialWithRecipes} from '../../../types'

import {MaterialForm} from './MaterialForm'

import {useAssetsQuery} from '@settings/modules/assets'
import {useScheduleQuery} from '@settings/modules/common/hooks'

interface MaterialSettingsGeneralTabProps {
  material: MaterialWithRecipes | undefined
}

export const MaterialSettingsGeneralTab: React.FC<MaterialSettingsGeneralTabProps> = ({
  material
}) => {
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')
  const {data: schedule} = useScheduleQuery()
  const {data: assets} = useAssetsQuery()

  const {data: globalMaterialOptions} = useGlobalMaterialsQuery(material?.type)
  if (!globalMaterialOptions) {
    throw new Error('BUG: global materials data should be loaded before rendering this component')
  }

  return assets && schedule ? (
    <MaterialForm
      material={material}
      key={material?.id ?? 'new'}
      isReadOnly={!canChangeMaterials}
      schedule={schedule}
      assets={assets}
      globalMaterialOptions={globalMaterialOptions}
    />
  ) : (
    <Skeleton variant="rectangular" height={96} />
  )
}
