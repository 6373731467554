import {useNotification} from '@hconnect/uikit/src/common'
import type {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../../api/mutations'
import {invalidateSettingsQuery} from '../../../../common/providers/ApiInterface'
import {notifyIfErrorMessage} from '../../../../common/utils/errorHandling'

export const useDeleteMaterialProductLink = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<
    void,
    AxiosError<{message: string}>,
    Parameters<typeof mutations.deleteMaterialProductLink>[0]
  >(mutations.deleteMaterialProductLink, {
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.message, raiseError, notify)
    },
    onSuccess: (data, variables) => {
      notify('success', t('success.deleteProduct'))
      invalidateSettingsQuery('materials', variables.plantCode)
      invalidateSettingsQuery('materialWithRecipes', variables.plantCode, variables.materialId)
    }
  })
}
