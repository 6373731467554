import {dataTestId} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box, Grid, Stack} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {PlantStructureFilterOptions} from '../../enums'

import {ItemDetails} from './ItemDetails'
import {ItemDetailsProvider} from './ItemDetailsProvider'
import {ItemLinkProvider} from './ItemLinkContext'
import {PlantStructureTree} from './PlantStructureTree'

import {QuickFilterOptionButton} from '@settings/modules/common/components/settingsTree'

export const PlantStructure = () => {
  const {t} = useTranslation()
  const [selectedFilterOption, setSelectedFilterOption] = useState<PlantStructureFilterOptions>(
    PlantStructureFilterOptions.All
  )

  return (
    <ItemLinkProvider>
      <Grid container spacing={2} {...dataTestId('plant-structure-container')}>
        <Grid item xs={12} sm={4}>
          <CardBox px={0}>
            <Stack
              direction="column"
              justifyContent="space-between"
              {...dataTestId('janus-plant-structure-menu')}
              alignItems={'left'}
              gap={2}
            >
              <Box
                gap={1}
                px={2}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: {xs: 'column', sm: 'row'}
                }}
              >
                {Object.values(PlantStructureFilterOptions).map((option) => (
                  <QuickFilterOptionButton
                    data-selected={option === selectedFilterOption}
                    onClick={() => setSelectedFilterOption(option)}
                    key={option}
                    variant="outlined"
                    {...dataTestId(`plant-structure-type-filter-${option}`)}
                  >
                    {t(`janusConfig.label.${option}`)}
                  </QuickFilterOptionButton>
                ))}
              </Box>
              <PlantStructureTree selectedFilterOption={selectedFilterOption} />
            </Stack>
          </CardBox>
        </Grid>
        <Grid item xs={12} sm={8}>
          <ItemDetailsProvider>
            <ItemDetails />
          </ItemDetailsProvider>
        </Grid>
      </Grid>
    </ItemLinkProvider>
  )
}
