import {useForm, UseFormReturn} from 'react-hook-form'

import {useGlobalMaterialsQuery} from '../../../hooks'
import {MaterialWithRecipes} from '../../../types'

import {
  useMaterialFormDefaultValues,
  MaterialFormDefaultValues
} from './useMaterialFormDefaultValues'

export const useMaterialForm = (
  material: MaterialWithRecipes | undefined
): UseFormReturn<MaterialFormDefaultValues> => {
  const {data: globalMaterialOptions} = useGlobalMaterialsQuery(material?.type)
  if (!globalMaterialOptions) {
    throw new Error('BUG: global materials data should be loaded before using this hook')
  }

  const materialFormDefaultValues = useMaterialFormDefaultValues(material, globalMaterialOptions)

  const materialForm = useForm<MaterialFormDefaultValues>({
    mode: 'onSubmit',
    shouldFocusError: false,
    defaultValues: materialFormDefaultValues
  })

  return materialForm
}
