import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete'
import Popper from '@mui/material/Popper'
import {styled} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'
import {Controller} from 'react-hook-form'
import {VariableSizeList, ListChildComponentProps} from 'react-window'

import {BaseTextField} from '../../../common/components'

const LISTBOX_PADDING = 8
const itemSize = 48

function renderRow(props: ListChildComponentProps) {
  const {data, index, style} = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  }
  const {key, ...optionProps} = dataSet[0]

  return (
    <Typography key={key} component="li" {...optionProps} noWrap style={inlineStyle}>
      {dataSet[1].label}
    </Typography>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current !== null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  (props, ref) => {
    const {children, ...other} = props
    const itemData: React.ReactElement<unknown>[] = []

    ;(children as React.ReactElement<unknown>[]).forEach(
      (
        item: React.ReactElement<unknown> & {
          children?: React.ReactElement<unknown>[]
        }
      ) => {
        itemData.push(item)
        itemData.push(...(item.children || []))
      }
    )

    const itemCount = itemData.length

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize
      }
      return itemCount * itemSize
    }

    const gridRef = useResetCache(itemCount)

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={() => itemSize}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    )
  }
)

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
})

export const EquipmentAutocomplete = ({
  options,
  control,
  formDataName,
  rules,
  label,
  helperText
}: {
  options: {
    label: string
    id: string
    path: string
  }[]
  control: any
  formDataName: any
  rules: any
  label: string
  helperText?: string
}) => {
  return (
    <Controller
      name={formDataName}
      control={control}
      rules={rules}
      render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
        <Autocomplete
          fullWidth
          disableListWrap
          disableClearable
          PopperComponent={StyledPopper}
          value={value}
          ListboxComponent={ListboxComponent}
          options={options}
          onChange={(e, data) => onChange(data)}
          renderInput={(params) => (
            <BaseTextField
              {...params}
              helperText={invalid ? (error?.message ? error.message : 'Invalid value') : helperText}
              error={invalid}
              label={label}
            />
          )}
          renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
          sx={{
            '& .MuiInputBase-root': {
              height: '52px',
              borderRadius: '4px',
              background: '#fbfbfc',
              boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.06)',
              // mb: helperText && 1,
              '&.Mui-focused': {
                background: '#fbfbfc'
              }
            }
          }}
        />
      )}
    />
  )
}
