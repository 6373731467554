import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../api/mutations'
import {invalidateSettingsQuery} from '../../../../modules/common/providers/ApiInterface'
import {notifyIfErrorMessage} from '../../../../modules/common/utils/errorHandling'
import type {ErrorResponse} from '../../../common/types'

export type EditMaterialParams = typeof mutations.editMaterial

export const useEditMaterial = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<void, AxiosError<ErrorResponse>, Parameters<EditMaterialParams>[0]>(
    mutations.editMaterial,
    {
      mutationKey: 'editMaterial',
      onError: (error) => {
        notifyIfErrorMessage(error.response?.data.message, raiseError, notify)
      },
      onSuccess: () => {
        notify('success', t('success.editMaterial'))
      },
      onSettled: (data, error, variables) => {
        invalidateSettingsQuery('materials', variables.plantCode)
        invalidateSettingsQuery('materialWithRecipes', variables.plantCode, variables.materialId)
      }
    }
  )
}
