import {MaterialType} from '@hconnect/common/types'
import {useMemo} from 'react'
import {useIsMutating} from 'react-query'

import {usePermission} from '../../../../permissions'
import {MaterialWithRecipes} from '../../../types'

import {CementClassification} from './CementClassification'

interface MaterialSettingsClassificationTabProps {
  material: MaterialWithRecipes
}

export const MaterialSettingsClassificationTab: React.FC<
  MaterialSettingsClassificationTabProps
> = ({material}) => {
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')

  const runningMutations = useIsMutating(['editMaterial'])
  const isMaterialSaving = Boolean(runningMutations)

  const ClassificationTabComponent = useMemo(() => {
    switch (material.type) {
      case MaterialType.Cement:
        return CementClassification
      default:
        throw new Error(`BUG: Unknown material type: ${material.type}`)
    }
  }, [material.type])

  return (
    <ClassificationTabComponent
      material={material}
      isDisabled={!canChangeMaterials || isMaterialSaving}
    />
  )
}
