import {Loader} from '@hconnect/uikit/src/lib2'

import {useSelectedItem} from '../../../janus/components/SelectedItemProvider'
import {SignalDataType, SignalFormData} from '../../types/signal'

import {useCreateSignal} from './api/useCreateSignal'
import {useSignalOptions} from './hooks/useSignalOptions'
import {SignalForm} from './SignalForm'
import {getEquipmentPath} from './SignalForm.helpers'

export const NewSignal = ({
  onCreated,
  onGoBack
}: {
  onCreated: (signalId: string) => void
  onGoBack: () => void
}) => {
  const {equipment, signalTypes, units, optionsLoading} = useSignalOptions()
  const {mutateAsync} = useCreateSignal((sensorId) => onCreated(sensorId))

  const {selectedItem} = useSelectedItem()

  const signalEquipment =
    selectedItem?.entityType === 'Equipment'
      ? equipment?.find((equipment) => equipment.id === selectedItem.id)
      : null

  const defaultValues: SignalFormData = {
    localName: null,
    source: null,
    dataType: SignalDataType.RAW,
    name: '',
    type: null,
    unit: null,
    equipment: signalEquipment
      ? {
          id: signalEquipment.id,
          label: signalEquipment.text ?? 'Unknown',
          path: getEquipmentPath(signalEquipment)
        }
      : null,
    description: '',
    customThresholds: {
      high: null,
      highStatus: '',
      low: null,
      lowStatus: ''
    },
    modelConfidence: {
      value: null,
      status: ''
    },
    thresholds: {
      high: null,
      highHigh: null,
      low: null,
      lowLow: null,
      highStatus: '',
      lowStatus: '',
      highHighStatus: '',
      lowLowStatus: ''
    },
    timeToBreach: {
      high: '',
      highHigh: null,
      low: null,
      lowLow: null
    }
  }

  return optionsLoading ? (
    <Loader />
  ) : (
    <SignalForm
      defaultValues={defaultValues}
      equipment={equipment ?? []}
      units={units ?? []}
      onSubmit={mutateAsync}
      onGoBack={onGoBack}
      signalTypes={signalTypes ?? []}
      steppedSetup
    />
  )
}
