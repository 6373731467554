import type {Product, Material} from '../../../../types'

/**
 * function to get available products for a specific material
 */

export const getAvailableProducts = (products: Product[], material: Material) => {
  const productsIdsUsed = material.products.map(({id}) => id)
  return products.filter((product) => !productsIdsUsed.includes(product.id))
}
