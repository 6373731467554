import {Checkbox as MUICheckbox, FormControlLabel} from '@mui/material'
import type {FormControlLabelProps} from '@mui/material'
import React from 'react'

interface CheckboxProps
  extends Pick<FormControlLabelProps, 'inputRef' | 'disabled' | 'onBlur' | 'onKeyDown'> {
  label?: string
  ['data-test-id']?: string
  value: boolean
  checked?: boolean
  onChange?: (checked: boolean) => void
}

export const Checkbox = ({label, value, checked, onChange, ...otherProps}: CheckboxProps) => {
  return (
    <FormControlLabel
      sx={!label ? {ml: 0, mr: 0} : {ml: 0}}
      control={<MUICheckbox />}
      label={label}
      checked={checked ?? value}
      onChange={(e, checked) => onChange?.(checked)}
      {...otherProps}
    />
  )
}
